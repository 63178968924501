.breadcrumb {
    margin-left  : 8em;
    margin-bottom: 5ex;

    .breadcrumb-item {

        a {
            color          : #aaa;
            text-decoration: underline;
        }

        &.shorten-long-text {
            a {
                color          : #333;
                text-decoration: none;
            }
        }

        &:before {
            content: ">"
        }
    }
}

.mainNav {
    .active {
        * {
            color: #f10083 !important;
        }
    }
}

#slider-article-slide {

    &.carousel {

        .carousel-indicators {
            bottom       : 0;
            left         : 25%;
            margin-bottom: 2ex !important;

            li {
                border-color: #f10083;

                &.active {
                    background-color: #f10083;
                }
            }
        }

        .carousel-caption {
            bottom: 15%;
        }

        &.fachthemen-slider {
            .carousel-caption {
                bottom: 10%;

                h4 {
                    font-size: 3rem;
                }
            }
        }

        .carousel-control-next,
        .carousel-control-prev {
            position       : absolute;
            top            : 0;
            bottom         : 0;
            display        : -ms-flexbox;
            display        : flex;
            -ms-flex-align : center;
            align-items    : center;
            -ms-flex-pack  : center;
            justify-content: center;
            width          : 15%;
            color          : #fff;
            text-align     : center;
            opacity        : .5;
            color          : #f10083;

            &:focus,
            &:hover {
                // color          : #fff;
                text-decoration   : none;
                outline           : 0;
                opacity           : .9;
            }

            i {
                font-size: 5rem;
            }

        }

        .carousel-control-prev {
            left: 0;


        }

        .carousel-control-next {
            right: 0;

        }

        .slider-articles-teaser {

            .active {
                .teaserTitle {
                    color: #f10083;
                }
            }

            .teaserStrapLine {
                // height: 5ex;
                line-height: 0.9rem;
            }

            .teaserTitle {
                // height: 8ex;
            }

        }
    }
}



.newsletter-wrapper {
    float: right;
}