@color_primary: #f10183;
@color_primary_700: #984398;
@color_primary_800: #652d65;
@color_primary_900: #4c224c;

@color_yellow: #fce18a;
@color_yellow_700: #fcdd82;
@color_yellow_800: #fbd56d;
@color_yellow_900: #facd5a;

@color_primary_background: #f5ecf5;

@background: #fefefe;
@background_75: #fbfbfb;
@background_100: #f7f8f8;
@background_200: #fcfbfc;
@background_250: #f7f8f8;
@background_300: #f0f0f0;
@background_350: #ebebeb;
@background_400: #c3c6c6;

@grey: #b4b8b8;
@grey_600: #a2a6a6;
@grey_700: #737373;
@grey_800: #5b5c5c;
@grey_900: #333333;

@heading_light: #ffffff;
@body_light: #ffffff;
@textfields: #9b9b9b;
@body_2: #909090;
@body_1: #665966;
@heading_dark: #310f36;
@error: #e7597c;

@black-text-color: #000;
@white-text-color: #fff;

body {
  background-color: @background !important;
  color: @body_1;
  letter-spacing: 0.4px;

  overflow-x: hidden !important;

  .sidebar-wrapper {
    margin-top: 15.5ex;
  }
}

.csPreloader {
  background-color: #ffffff99 !important;

  .spinner-layer {
    display: flex;
  }
}

// Guidelines
.btn {
  text-transform: none !important;

  //font-family: 'Proxima Nova Alt Rg';
  font-family: "Proxima Nova Alt Rg";
  font-style: normal;
  font-weight: normal;

  font-size: 16px;

  &.btn-universimed-portal {
    border-radius: 2em !important;
    padding: 0.6em 1em;
    box-shadow: none;
    background-color: @color_primary !important;
    // font-size       : 0.7rem;
    min-width: 12em;
    margin: 0;

    &:hover {
      background-color: @color_primary_700 !important;
    }

    &:active {
      background-color: @color_primary_800 !important;
    }

    &:disabled {
      background-color: @grey !important;
    }
  }

  &.btn-universimed-portal-yellow {
    border-radius: 2em !important;
    padding: 0.6em 1em;
    box-shadow: none;
    background-color: @color_yellow !important;
    // font-size       : 0.7rem;
    min-width: 12em;
    margin: 0;
    color: @black-text-color !important;

    &:hover {
      background-color: @color_yellow_700 !important;
    }

    &:active {
      background-color: @color_yellow_800 !important;
    }

    &:disabled {
      background-color: @grey !important;
    }
  }

  &.btn-universimed-portal-purple {
    border-radius: 2em !important;
    padding: 0.6em 1em;
    box-shadow: none;
    background-color: @color_primary_900 !important;
    // font-size       : 0.7rem;
    min-width: 12em;
    margin: 0;
    color: @white-text-color !important;

    &:hover {
      background-color: @color_primary_800 !important;
    }

    &:active {
      background-color: @color_primary_700 !important;
    }

    &:disabled {
      background-color: @grey !important;
    }
  }

  &.btn-universimed-portal-outline {
    border-radius: 2em !important;
    padding: 0.6em 1em;
    box-shadow: none;
    background-color: unset !important;
    color: @color_primary !important;
    // font-size       : 0.7rem;
    min-width: 12em;
    margin: 0;
    border-color: @color_primary !important;
    border-width: 1px;
    border-style: solid;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: @color_primary_700 !important;
      background-color: @color_primary_background !important;
      border-color: @color_primary_700 !important;
    }

    &:active {
      color: @color_primary_800 !important;
      background-color: @color_primary_background !important;
      border-color: @color_primary_800 !important;
    }

    &:disabled {
      background-color: @background_350 !important;
      color: @grey !important;
      border-color: @grey !important;
    }
  }

  &.btn-universimed-portal-flat {
    border-radius: none;
    border: none;
    padding: 0.6em 1em;
    box-shadow: none;
    background-color: none !important;
    // font-size       : 0.7rem;
    min-width: 12em;
    margin: 0;

    // text-transform: uppercase;
    color: @white-text-color;
  }

  /*&.btn-universimed-portal-flat-icon {
        border-radius   : 2em !important;
        padding         : 0.6em 1em;
        box-shadow      : none;
        background-color: @color_primary  !important;
        font-size       : 0.7rem;
        min-width       : 12em;
        margin          : 0;

    }

    &.btn-universimed-poral-textlink {
        border-radius   : 2em !important;
        padding         : 0.6em 1em;
        box-shadow      : none;
        background-color: @color_primary  !important;
        font-size       : 0.7rem;
        min-width       : 12em;
        margin          : 0;

    }

    &.btn-universimed-portal-icononly {
        border-radius   : 2em !important;
        padding         : 0.6em 1em;
        box-shadow      : none;
        background-color: @color_primary  !important;
        font-size       : 0.7rem;
        min-width       : 12em;
        margin          : 0;

    }*/
}

.customQuickbar {
  .btn-universimed-portal-flat {
    border-radius: none;
    border: none;
    padding: 0 !important;
    box-shadow: none;
    background-color: none !important;
    // font-size       : 0.7rem;
    min-width: unset;
    margin: 0;

    // text-transform: uppercase;
    color: @white-text-color;
    margin-right: 2em;
  }
}

.load-more_btn,
.load-more_btn_newest {
  border-radius: 2em !important;
  padding: 0.6em 1em;
  box-shadow: none;
  background-color: unset !important;
  color: @color_primary_700 !important;
  // font-size       : 0.7rem;
  min-width: 12em;
  margin: 0;
  border-color: @color_primary_700 !important;
  border-width: 1px;
  border-style: solid;
  font-weight: bold;

  &:hover {
    color: @color_primary_700 !important;
    background-color: @color_primary_background !important;
    border-color: @color_primary_700 !important;
  }

  &:active {
    color: @color_primary_800 !important;
    background-color: @color_primary_background !important;
    border-color: @color_primary_800 !important;
  }

  &:disabled {
    background-color: @background_350 !important;
    color: @grey !important;
    border-color: @grey !important;
  }

  &.loading {
    i {
      animation: spin 10s linear infinite;
    }
  }

  i {
    display: none;
  }

  a {
    color: @color_primary_700 !important;
  }
}

// Custom.less
.fach-list {
  width: 100%;
  overflow: auto;

  .fach-list-wrapper {
    min-width: max-content;
  }
}

.hack-scroll {
  width: 100%;
  text-align: center;
  height: 3ex;
  margin-top: -2.5ex;
  background: white;
  z-index: 99;
}

.newest-articles-swipe {
  padding: 2ex;
  background: @background_350;

  .newest-articles-wrapper {
    width: 80%;
    max-width: 1280px;
    margin: auto;

    .article-list {
      width: 100%;
      overflow: auto;

      .article-list-wrapper {
        min-width: 65em;

        .news-article {
          width: 30%;
          padding: 1em;
          border-left: 1px solid @background_400;
          color: @heading_dark;
          font-weight: 800;

          &:first-child {
            border-left: none;
            padding-left: 0;
          }
        }
      }
    }

    .hack-scroll {
      background: @background_350;
      height: 3ex;
    }
  }
}

.theme_list {
  margin-left: -15px;
  margin-right: -15px;

  h3.title {
    min-height: 3.85ex;
  }

  .configurable-widget {
    // padding-right: 15px;

    .image {
      padding: 0 !important;
    }
  }
}

.navbar {
  background-color: @background !important;
  padding: 0;
  border: none;
  position: relative;
  box-shadow: none;

  .container {
    width: 80%;
    max-width: 1280px;

    .logo-wrapper {
      padding-left: 0;
      margin-right: 10px;
    }
  }

  .navbar {
    padding: 0;
    box-shadow: none;

    &.navbar-dark {
      background: @white;

      .upper-nav {
        background-color: @color_primary_800;
        margin: 0;
        padding: 0.3em 1em;

        height: 8.5ex;
        display: flex;
        align-items: center;
        flex-direction: row;

        .links {
          .link {
            margin-right: 1em;
            height: auto;
            width: auto;
            max-width: 7em;
            max-height: 1.5em;
            min-height: 0.7em;
            image-rendering: -webkit-optimize-contrast;

            img {
              path {
                fill: "currentColor";
              }
            }

            &.urologik {
              max-height: 0.1em;
              //opacity: 0.6;
            }

            &.meddiplom {
              width: 5.5em;
            }

            &.leading {
              max-height: 1.7em;
            }
          }
        }

        .right {
          padding: 0;

          .customQuickbar {
            margin: 0;
            padding: 0;
            border: none;
            float: right;
            margin-right: 1em;

            .quickbar-item {
              display: inline;
              margin-left: 0.5em;

              a {
                //font-family: 'Proxima Nova Alt Bold';
                font-family: "Proxima Nova Alt Rg";
              }
            }

            .dropdown-menu {
              .dropdown-item {
                //font-family: 'Proxima Nova Alt Rg';
                font-family: "Proxima Nova Alt Rg";
              }
            }
          }
        }
      }

      .bottom-nav {
        margin: 0;
        padding: 0 1em;
        margin-top: 2ex;
        margin-bottom: 2ex;

        .bookmark-wrapper {
          i.fa-bookmark {
            margin-left: 15px;
            padding-top: 1px;
          }
        }

        .search-field {
          color: @heading_dark;

          form {
            width: 22em;
          }

          i {
            position: absolute;
            left: 1em;
            margin-top: 0.8ex;
          }

          input {
            padding-left: 2em;
            width: 18.5em;

            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: @textfields !important;
              opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: @textfields !important;
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              color: @textfields !important;
            }
          }
        }

        .search {
          .btn-universimed-portal {
            padding: 0.75rem 0.5rem;
          }
        }

        .mainNav {
          background: @white;
          box-shadow: none;

          .btn {
            font-weight: bold;
            // padding      : 0.858rem;  (old version)
            padding: 0.858rem 10px 0.858rem 10px;
            //font-family: 'Roboto Slab';
            font-family: "Proxima Nova Alt Rg";

            &.fachthemen:focus {
              background-color: transparent !important;
              color: @color_primary !important;
            }
          }

          .open > .btn-primary.dropdown-toggle,
          .btn-primary:focus {
            background-color: transparent !important;
            color: @color_primary !important;
          }
        }

        .navbar-brand {
          width: 100%;
        }
      }

      .navigation-wrapper {
        margin-right: 0;
        margin-left: 0;
      }

      .navbar-brand {
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }

      .search {
        display: none;

        form {
          width: 80%;

          .form-input {
            width: 60%;
          }
        }
      }

      .fa {
        cursor: pointer;
        float: right;
        margin-top: 1ex;

        &.navigation {
          color: @color_primary;
        }
      }
    }
  }

  .fachthemen-menu {
    background-color: @background;
    padding-right: 2.5em;

    position: relative;
    padding-left: 0;

    .more {
      position: absolute;
      top: 0;
      right: 0.3em;
      font-size: 2.8em;
      color: @color_primary_700 !important;
      gap: 1ex;
      margin-top: -0.45em;

      font-weight: 800;
      letter-spacing: 0px;

      padding-left: 0.9em;
      background: @white;
      background: linear-gradient(
        90deg,
        transparent 0%,
        @white 30%,
        @white 100%
      );

      i {
        cursor: pointer;
      }
    }

    .fach-list {
      padding-right: 1.3em;

      .fach-list-wrapper {
        padding-bottom: 1ex;
        padding-right: 2em;

        span {
          cursor: pointer;
          margin-bottom: 1.5ex;

          padding-right: 0.5em;

          &.active {
            padding-left: 0.5em;
            color: @color_primary_800;
            background: @color_primary_background;
            border: solid 1px @color_primary_800;
            border-radius: 1em;

            &::after {
              border-bottom: 0.3em solid;
              border-top: 0;
            }
          }

          &.current {
            padding-left: 0.5em;
            // color: @color_primary_800;
            // background: @color_primary_background;
            border: solid 1px @color_primary_800;
            border-radius: 1em;

            &::after {
              border-bottom: 0.3em solid;
              border-top: 0;
            }
          }
        }

        .sublist {
          border: solid 1px transparent;

          &::after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 0.3em;
            vertical-align: middle;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-left: 0.3em solid transparent;
          }
          &:first {
            // padding-left: 0;
          }
        }
      }
    }
  }

  .fachthemen-submenu {
    position: absolute;
    background: white;
    z-index: 10;
    width: 100%;
    max-width: unset;
    padding: 0;
    display: none;

    .more-wrapper {
      position: absolute;
      width: 80%;
      max-width: 1280px;
      height: 100%;
      pointer-events: none;

      .more {
        margin-right: -1em;
        padding-right: 1em;

        pointer-events: all;

        position: absolute;
        top: 0;
        right: 0.3em;
        font-size: 2.8em;
        color: @color_primary_700 !important;
        gap: 1ex;
        margin-top: -0.45em;

        font-weight: 800;
        letter-spacing: 0px;

        padding-left: 0.9em;
        background: @white;
        background: linear-gradient(
          90deg,
          transparent 0%,
          @white 30%,
          @white 100%
        );

        i {
          cursor: pointer;
        }
      }
    }

    .fach-list {
      padding-left: 0 !important;
      padding-bottom: 1ex;
      padding-right: 5em !important;

      span {
        cursor: pointer;
        margin-bottom: 1.5ex;

        padding-right: 0.5em;

        &.current {
          padding-left: 0.5em;
          color: @color_primary_800;
          background: @color_primary_background;
          border: solid 1px @color_primary_800;
          border-radius: 1em;

          &::after {
            border-bottom: 0.3em solid;
            border-top: 0;
          }
        }
      }
      width: 80%;
      max-width: 1280px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

article {
  .teaserStrapLine {
    margin-bottom: 0.5rem;
    font-size: 14px;
    color: @color_primary_700;
  }

  .teaserTitle {
    // font-weight: 800;
    color: @heading_dark;

    p {
      margin-bottom: 0.5rem;
      //font-weight: 500;
      //font-size: 1.3rem;
      //line-height: normal;
    }
    h4 {
      margin: 0;
      padding: 0;
      width: 100%;
      text-decoration: none;
      font-size: 1.3rem;
      margin-bottom: 1ex;
    }
  }

  .teaserText {
    margin-bottom: 2rem;
    line-height: 1.2;
  }

  .top-teaser-list {
    position: relative;

    .teaser-chips-wrapper-no-image,
    .teaser-chips-wrapper {
      //float: left;
      position: absolute;
      z-index: 99;
      bottom: 0;

      .teaser-chips {
        padding: 5px;
        border-radius: 40px;
        font-size: 0.7rem;
        border-width: 1px;
        border-color: white;
        margin: 11px 0px 10px 12px;
        color: white;
        border-style: solid;
        background-color: #4c224c;
        height: 24px;
        float: left;

        span {
          margin-left: 5px;
          margin-right: 5px;
          position: relative;
          top: -1px;
        }
      }

      .teaser-chips-promotion {
        padding: 4px 10px 4px;
        border-radius: 40px;
        font-size: 0.7rem;
        border-width: 1px;
        border-color: white;
        margin: 11px 0px 10px 12px;
        color: white;
        border-style: solid;
        background-color: #984398;
        height: 24px;
        /* left: 74px; */
        float: left;
      }

      .teaser-chips-podcast {
        padding: 4px 10px 4px;
        border-radius: 40px;
        font-size: 0.7rem;
        border-width: 1px;
        border-color: #984398;
        margin: 11px 0px 10px 12px;
        color: #984398;
        border-style: solid;
        background-color: white;
        height: 24px;
        /* left: 74px; */
        float: left;
      }

      .teaser-chips-video {
        padding: 4px 10px 4px;
        border-radius: 40px;
        font-size: 0.7rem;
        border-width: 1px;
        border-color: #984398;
        margin: 11px 10px 10px;
        color: #984398;
        border-style: solid;
        background-color: white;
        height: 24px;
        /* left: 74px; */
        float: left;
      }
    }
  }

  .teaser-chips-wrapper-no-image {
    position: relative;
    clear: both;
    display: block;
    width: 100%;
    min-height: 5ex;

    .teaser-chips {
      margin: 0;
    }
  }
}

.main-stage-articles {
  .teaserTitle {
    font-size: 18px;
  }

  .article-list {
    .article-list-wrapper {
      gap: 25px;
    }
  }
}

.video-articles,
.main-stage-articles {
  .main-article {
    .teaserTitle {
      font-size: 26px;
      font-weight: bold;
      line-height: 1.9rem;
    }
  }
}

.title {
  padding-left: 0;
  text-decoration: none !important;
  font-size: 20px !important;
  margin-left: 0 !important;
  font-weight: 800 !important;

  color: @heading_dark;
}

.most-read-articles {
  .title {
    padding-left: 0;
  }
}

.main-content-wrapper {
  width: 80%;
  max-width: 1280px;
  margin: auto;
}

footer {
  background-color: @background_350 !important;
  padding-top: 0 !important;

  .footer-newsletter {
    background-color: @color_primary_800;
    min-height: 10ex;
    width: 100%;

    padding-top: 5em;
    padding-bottom: 5em;

    .newsletter-footer-wrapper {
      .newsletter-footer-content-wrapper {
        .newsletter-title {
          text-transform: none;
          text-align: center;
          font-size: 32px;
          line-height: 1.2;
          margin-bottom: 1ex;
        }

        .newsletter-sub-title {
          text-transform: none;
          text-align: center;
          margin-bottom: 2ex;
          opacity: 0.8;
        }

        .form-newsletter {
          width: 100%;

          .checkboxes {
            margin-top: 4ex;

            .check-wrapper {
              margin-bottom: 1ex;

              opacity: 0.8;

              label {
                font-size: 12px;

                &:before {
                  border-color: @white;
                }
              }
            }
          }

          .form-input {
            &.email {
              width: 100%;
              border: none !important;
              border-radius: 0.5em !important;

              height: 10ex;
            }
          }

          .button-wrapper {
            position: absolute;
            top: 0;
            right: 0.5em;
            height: 100%;
          }
        }
      }
    }
  }

  .footer-navigation {
    // background-color: @color_primary_background;
    min-height: 10ex;
    width: 80%;
    max-width: 1280px;
    padding-top: 5ex;
    padding-bottom: 5ex;
    color: @body_2;

    .footer-info {
      padding-right: 5em;
    }

    .main-footer {
      color: @heading_dark;
      font-size: 18px;
    }

    img {
      max-width: 13em;
      margin-top: 3ex;
      margin-bottom: 3ex;
    }

    i {
      font-size: 30px;
      color: @color_primary;
      margin-right: 0.3em;
    }
  }

  .title {
    font-size: 16px !important;
    text-transform: none !important;
  }
}

.article-content {
  padding-left: 15px;
  padding-right: 15px;
}

.article-content-wrapper {
  width: 80%;
  max-width: 1280px;

  .headImage {
    margin-left: 0;
    transform: translateX(0);
    margin-bottom: 0;
  }

  .source {
    text-align: right;
    font-size: small;
  }

  .teaserStrapLine {
    color: @color_primary_700;
  }

  .teaserTitle {
    font-weight: 800;
    color: @heading_dark;
  }

  .subtitle {
    font-weight: 500;
    font-size: 1.3rem;
    margin: 4ex 0;
    line-height: 1.5;
  }
}

.article-list {
  padding-right: 15px;

  .article-list-wrapper {
    // gap: 25px;
    justify-content: space-between;
  }

  /*&.multiple-article {
    .article-list-wrapper {
      gap: 22px;
      justify-content: flex-start;
    }
  }*/

  .article-wrapper,
  .article,
  article {
    max-width: 300px;
    margin-bottom: 3ex;

    @media screen and (max-width: 4000px) {
      max-width: 33%;

      article {
        max-width: 100%;
      }
    }

    &.fitBanners {
      @media screen and (max-width: 1550px) {
        max-width: 32%;
      }

      @media screen and (max-width: 1500px) {
        max-width: 49%;
        flex: 0 0 49%;
      }

      @media screen and (max-width: 1150px) {
        max-width: 48%;
        flex: 0 0 49%;
      }

      @media screen and (max-width: 1100px) {
        max-width: 52%;
        flex: 0 0 44%;
      }

      @media screen and (max-width: 1050px) {
        max-width: 55%;
        flex: 0 0 42%;
      }
    }

    &.emptyBanner {
      display: none;
    }
  }

  &.multiple-article {
    .article-wrapper {
      @media screen and (max-width: 2000px) {
        max-width: 31%;
      }
    }
  }
}

.news-list,
.int-krongresse {
  .title {
    margin-top: 0 !important;
  }

  .row {
    margin-left: -5px;
  }
}

.related-rubric-articles {
  background-color: @background_light;
  .article-list {
    padding-right: 0;

    .article-wrapper {
      max-width: 100%;
    }
  }
}

.search-articles {
  width: 100%;
}

.facthemen-articles {
  .title {
    cursor: pointer;
  }
}

.main-content-wrapper {
  .content-c-wrapper {
    max-width: ~"calc(100% - 320px)";

    @media screen and (max-width: 1024px) {
      max-width: unset;
    }
  }

  .sidebar-wrapper {
    min-width: 320px;
  }
}

.socialIcons {
  text-align: center;

  .shariff-button {
    max-width: unset !important;
    width: 105% !important;
  }
}

.subscribe {
    background-color: #F2F2F2;
    border-radius: 0.5em !important;

    @media screen and (max-width: 1024px) {
      background-color: unset;
      border-radius: 0.5em unset;
    }

    .subscribe-email {
      width: 67%!important;
      box-shadow: unset !important;

      @media screen and (max-width: 1650px) {
        width: 60%!important;
      }

      @media screen and (max-width: 1450px) {
        width: 50%!important;
      }

      @media screen and (max-width: 1150px) {
        width: 40%!important;
      }

      @media screen and (max-width: 1024px) {
        width: 100%!important;
      }
    }
}

.keywordFilter {
  margin: 0px 0px 20px -5px;

  .keyword-wrapper {
    margin: 0 5px 0 5px;
    cursor: pointer;
    display: inline-block;

    .keyword {
      border-radius: 15px;
      padding: 7px;
      background-color: @color_yellow;
    }

    .close {
      display: none;
    }

    &.selected {
      .keyword {
        background-color: @color_primary_900;
        color: @white-text-color;
      }

      .close {
        display: block;
        display: block;
        position: relative;
        top: -15px;
        left: -12px;
        border: 1px solid @color_primary_900;
        border-radius: 15px;
        z-index: 99;
        background-color: @white-text-color;
        opacity: unset;
        height: 22px;
        width: 22px;
        text-align: center;
        font-size: 18px;
      }
    }
    @media screen and (max-width: 500px) {
      margin-bottom: 20px;
    }
  }

}
.typeFilter {
  input{

    margin-bottom: 0!important;
    border-bottom: none!important;
    max-height: 40px;
    width: 120%!important;
    left: -1.3em;
    padding-left: 1.3em!important;
    background-color: #F2F2F2!important;
    border-radius: 2em;
  }
  .caret{
    z-index:9;
    font-size: 0!important;
    top: 14px!important;
  }
  .caret:before{
    font-family: FontAwesome;
    content: "\f078";
    font-size: 10px;
  }
}

.sidebar .configurable-widget.medium-rectangle.emptyBanner,  .sidebar-wrapper .configurable-widget.medium-rectangle.emptyBanner, .sidebar-wrapper .configurable-widget.skyscraper.emptyBanner,
.article-list .configurable-widget.medium-rectangle.emptyBanner {
  display: none;
}

.article-list-wrapper .article-wrapper.ldb.emptyLdbBanner, .main-content-widget .ldb.emptyLdbBanner, .article-header .ldb.emptyLdbBanner {
  display: none;
}