.mobileView {
  display: none !important;
}

.desktopView {
  display: block !important;
  padding-left: 0;
}

.desktopViewFlex {
  display: flex !important;
}

/* Desktop */
@media screen and (max-width: 2560px) {

  .article-142961 {
    .main-wrapper {
      aside {
        display: flex;
      }
    }
  }


  body {
    main {
      .main-wrapper {
        .container {


        }
      }
      
    }
  }

  .col-xs,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12 {
    padding-left: 10px;
    padding-right: 10px;
  }
}


.article-content-wrapper {
  .related-rubric-articles {
    padding-left: 10px;
    padding-right: 10px;

    h1.teaserTitle.h1-responsive{
      font-weight: 800;
      color: #310f36;
      font-size: 100% !important;
      padding-top: 10px;
      margin-bottom: 10px;
    }

    .article-list{

      .related_content{
        border-top: 1px solid #ccc;
        padding-top: 10px;

        .fx-flex-direction-column{
          .fx-flex-direction-row{
            .article-list-wrapper{
              .article-wrapper{
                margin-bottom: 0;
                article{
                  margin-bottom: 10px !important;

                  a{
                    text-decoration: none;
                  }

                }


              }
            }
          }
        }
      }
    }
  }
}


.typeFilter{
  margin-left: 3px;
}
.keywordFilter{
  margin-left: -20px;
}
@media screen and (max-width: 1440px) {
  .article-content{
    .paywall {
      height: 40%!important;

      p{
        font-size: 0.7rem!important;
      }
    }
    .video-article-list-wrapper {
      .videoDisabled {
        height: 44%!important;
      }
    }
  }
  body {
    main {
      .main-wrapper {
        .container {
        }
      }
      .article-list{
        .paywall {
          width: 60%;
          margin-top: 2.5%;
          margin-left: 2.5%;
          margin-right: 2.5%;
          height: 38%;
          p{
            font-size: 0.65rem;
            margin-bottom: 0.6rem;
          }
        }
      }
      .video-article-list-wrapper {
        .videoDisabled {
          height: 44%!important;
        }
      }

    }
  }

}

@media screen and (max-width: 1024px) {
  .mobileView {
    display: block !important;
  }

  .desktopViewFlex,
  .desktopView {
    display: none !important;
  }

  .container {
    padding: 0;
    margin: 0;
  }

  .btn {
    min-width: 9em !important;
  }

  .article-content{
    .paywall {
      height: 45%!important;
      p{
        font-size: 0.84rem!important;
      }
    }
    .video-article-list-wrapper {
      .videoDisabled {
        height: 58%!important;
      }
    }

  }

  body {
    header {
      .navbar {
        .container {
          .switch-land-selector-container {
            .dropdown-menu {
              position: absolute;
              top: 4ex;
              left: 0;
              z-index: 99999;
            }
          }

          width: 100%;
          .right {
            width: 100%;

            .customQuickbar {
              margin: 0 !important;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .switch-land-selector-container {
                .quickbar-item {
                  margin: 0 !important;

                  .btn {
                    display: flex;
                    align-items: center;
                  }
                }
              }
            }
          }
        }

        .bottom-nav {
          .container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .logo-wrapper {
              width: 40%;
              padding: 0;
            }

            .button-collapse {
              display: flex !important;
              align-items: center;
              width: 60%;
              justify-content: flex-end;

              color: @color_primary_800;
              gap: 2ex;

              .fa {
                margin-top: 0;
              }
            }
          }
        }
      }

      .fachthemen-menu {
        padding: 0 !important;
        margin: 0;
        width: 100% !important;

        .fach-list {
          padding-left: 15px;

          &:after {

            content: '';
            background: white;
            /* padding-right: 1em; */
            height: 100%;
            width: 3em;
            position: absolute;
            right: 0;
            background: linear-gradient(90deg, RGBA(255, 255, 255, 0) 0%,white 50%,white 100%);


          }
        }
      }

      .fachthemen-submenu {
        .fach-list {
          width: 100% !important;
          .fach-list-wrapper {
            padding-left: 15px;
          }
        }
      }
    }
    main {
      .mobile-menu {
        position: fixed;
        top: 18ex;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 999;

        background: @white;

        display: flex;
        padding: 15px;
        flex-direction: column;
        justify-content: space-between;

        .title {
          gap: 1ex;
          display: flex;

          i {
            color: @color_primary_700;
          }
        }

        .links {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 2ex;
          justify-content: center;

          form {
            width: 100%;

            i {
              position: absolute;
              right: 1em;
              top: 50%;
              transform: translateY(-50%);
            }

            input {
              margin-bottom: 0;
            }
          }
        }
      }
      .article-list{
        .paywall {
          width: 90%;
          margin-top: 5%;
          margin-left: 5%;
          margin-right: 5%;
          height: 35%;
          p{
            font-size: 1.2rem;
            margin-bottom: 0.7rem;
          }
        }
      }
      .video-article-list-wrapper {
        .videoDisabled {
          width: 100%;
          height: 40%!important;
        }
      }
      .article-content-wrapper {
        width: 90%;
        .article-content {


          .article-content-main {
            padding: 0;

            .main-content-widget {
              .topic-event-article{
                .article-list {
                  .article-list-wrapper {
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    padding: 0 20px;

                    .article-wrapper {
                      flex: 0 0 auto;
                      margin: auto;
                    }
                  }
                }
              }

              .multiple-article {
                .article-list-wrapper {
                  flex-direction: column;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  padding: 0 20px;

                  .article-wrapper {
                    flex: 0 0 auto;
                    margin: auto;
                  }
                }
              }
            }
          }
        }

        .related-articles {
          width: 100%;
          margin-left: 0;

          .title {
            line-height: 2.4rem;
          }

          .article-list-wrapper {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;

            .article-wrapper {
              flex: 0 0 auto;
              margin: auto;
            }
          }

          .article,
          article {
            max-width: unset;
            // padding: 10px !important;
          }
        }

        .related-rubric-articles {
          padding-left: 10px;
          padding-right: 10px;

          h1.teaserTitle.h1-responsive{
            font-weight: 800;
            color: #310f36;
            font-size: 100% !important;
            padding-top: 10px;
            margin-bottom: 10px;
          }

          .article-list{

            .related_content{
              border-top: 1px solid #ccc;
              padding-top: 10px;


              .fx-flex-direction-column{
                .fx-flex-direction-row{
                  .article-list-wrapper{
                    .article-wrapper{
                      margin-bottom: 0;
                      article{
                        margin-bottom: 10px !important;

                        a{
                          text-decoration: none;
                        }

                      }


                    }
                  }
                }
              }

            }

          }
        }

        .registration {
          padding-left: 15px;
          padding-right: 15px;

          .formContent {
            display: flex;
            flex-direction: column;
            gap: 4ex;
          }

          .caret {
            right: 0 !important;
          }

          .fachgebiet {
            ul {
              left: 0;
              width: 100% !important;
            }

            li {
              float: none;
              width: 85%;
              height: 25px;
            }
          }
        }

        .login {
          .col-xs-9 {
            width: 100%;
          }
          .modal-footer {
            padding: 0;
          }
          form,
          .text-xs-left {
            padding: 15px;
          }

          form {
            .col-xs-12 {
              padding: 15px;
            }

            input {
              max-width: unset;
            }
          }
        }

        .news-list {
          padding-left: 15px;
          padding-right: 25px;

          .article-list-wrapper {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;

            .article-wrapper {
              flex: 0 0 auto;
              margin: auto;
            }
          }
        }

        .int-krongresse {
          padding-left: 15px;
          padding-right: 25px;

          .title {
            line-height: 2.4rem;
          }
          .article-list-wrapper {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;

            .article-wrapper {
              flex: 0 0 auto;
              margin: auto;
            }
          }
        }
      }

      .main-wrapper {
        .container {
          .article-list {
            padding: 0;

            article,
            .article-wrapper {
              max-width: unset;
            }
          }

          article,
          .article {
            padding: 0;
          }

          .newest-articles-swipe {
            padding-left: 0;
            padding-right: 0;
            .newest-articles-wrapper {
              width: 100%;

              .article-list-wrapper {
                padding-left: 15px;
              }
            }
          }

          .most-read-articles {
            .article-list-wrapper {
              flex-direction: column;
              flex-wrap: nowrap;
            }
          }

          .main-stage-articles {
            width: 90%;
            .article-list-wrapper {
              flex-direction: column;

              article {
                padding-left: 10px !important;
                padding-right: 10px !important;
              }
            }

            .right-list {
              article {
                border-top: none;
              }
            }
          }

          .main-content-wrapper {
            width: 90%;

            & > .col-lg-9 {
              width: 100%;
            }

            .search-articles {
              padding: 0 3ex !important;

              .article-list-wrapper {
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;

                .article-wrapper {
                  flex: 0 0 auto;
                  margin: auto;
                }
              }
            }

            .theme_list {
              .article-list-wrapper {
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;

                .article-wrapper {
                  flex: 0 0 auto;
                  margin: auto;

                  &.medium-rectangle {
                    width: 100%;
                  }
                }
              }
            }

            .sidebar-wrapper {
              padding: 0;

              &.col-lg-3 {
                width: 100%;
              }
            }

            .newest-articles,
            .facthemen-articles {
              .article-list-wrapper {
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;

                .article-wrapper {
                  flex: 0 0 auto;
                  margin: auto;
                }
              }
            }


          }

          .multimediaPodcastWidget,
          .multimediaVideoWidget {
            .article-list-wrapper {
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: flex-start;
              gap: 25px;

              .article-wrapper {
                flex: 0 0 auto;
                margin: 0;
              }
            }

          }

          .video-articles {

            padding-left: 1.5em;
            padding-right: 1.5em;

            .video-article-list-wrapper {
              flex-direction: column;

              .video-list-right {
                article {
                  &:nth-child(n + 5) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    footer {
      .newsletter-footer-wrapper {
        .newsletter-footer-content-wrapper {
          flex: 0 0 90%;

          .button-wrapper {
            position: relative !important;
            padding-top: 4ex;
          }
        }
      }

      .footer-navigation {
        flex-direction: column-reverse;
        width: 90%;
        padding-top: 0;
        padding-bottom: 0;

        & > div {
          text-align: left;
        }

        .footer-info {
          padding-right: 0;
        }

        .navigation-wrapper {
          margin: 0;
          .main-footer {
            flex: 1 1 100%;
          }

          .normal-footer {
            flex: 1 1 50%;
            margin: 0;
            margin-top: 1ex;

            ul {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &.article-2060919,
    &.article-2060912,
    &.article-2060903,
    &.article-68432,
    &.article-138661,
    &.article-2190946,
    &.article-33860 {
      .article-content {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }

    &.article-138661 {
      .article-content {
        .select-wrapper {
          width: 100% !important;
          max-width: 100% !important;
        }

        .covers {
          width: 100%;
          .cover {
            width: 100%;
          }
        }
      }
    }

    .leftSidebar {
      position: absolute;
      background-color: white;
      width: 90%;
      left: 100%;

      .buttonResponsiveAside {
        display: block;
        position: absolute;
        background-color: @background_350;
        width: 4em;
        height: 4em;
        color: white;
        position: absolute;
        left: -3.5em;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        text-align: center;
        color: #f10083;

        i {
          font-size: 2em;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          vertical-align: bottom;
        }

        .fa-arrow-left {
          display: inline-block;
        }
        .fa-close {
          display: none;
        }

        &.opened {
          .fa-arrow-left {
            display: none;
          }
          .fa-close {
            display: inline-block;
          }
        }
      }

      .tableContent {
        margin-bottom: 2ex;
        margin-top: 2ex;

        .title {
          width: 95%;
        }
      }
    }

    .csWidget-wrapper {

      .title {
        font-size: 2rem !important;
      }

    }

    .csArticle-page_wrapper {

      h3 {
        font-size: 2rem !important;
        line-height: 2.3rem !important;
      }

    }

  }
  .typeFilter{
    margin-left: 12px;
  }
  .keywordFilter{
    margin-left: 0px;
  }
}

/* Tablet */
@media screen and (max-width: 768px) {

  .article-content{
    .paywall {
      height: 46%!important;

      p{
        font-size: 0.74rem!important;
      }
    }
    .video-article-list-wrapper {
      .videoDisabled {
        height: 59%!important;
      }
    }
  }
  body {
    main {
      .main-wrapper {
        .container {
        }
      }
      .article-list{
        .paywall {
          width: 95%;
          margin-top: 2.5%;
          margin-left: 2.5%;
          margin-right: 2.5%;
          height: 27%;
          p{
            font-size: 0.74rem;
            margin-bottom: 0.6rem;
          }
        }
      }
      .video-article-list-wrapper {
        .videoDisabled {
          height: 30%!important;
        }
      }
    }
  }
}

/* Mobile */
@media screen and (max-width: 425px) {

  .article-content{
    .paywall {
      height: 43%!important;

      p{
        font-size: 0.64rem!important;
      }
    }
    .video-article-list-wrapper {
      .videoDisabled {
        height: 48%!important;
      }
    }
  }

  body {
    main {
      .main-wrapper {
        .container {
        }
      }
      .article-list{
        .paywall {
          width: 95%;
          margin-top: 2.5%;
          margin-left: 2.5%;
          margin-right: 2.5%;
          height: 18%;
          p{
            font-size: 0.54rem;
            margin-bottom: 0.6rem;
          }
        }
      }
      .video-article-list-wrapper {
        .videoDisabled {
          height: 18%!important;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {

  .article-content{
    .paywall {
      height: 39%!important;

      p{
        font-size: 0.54rem!important;
      }
    }
    .video-article-list-wrapper {
      .videoDisabled {
        height: 41%!important;
      }
    }
  }
  body {
    main {
      .main-wrapper {
        .container {
        }
      }
      .article-list{
        .paywall {
          width: 95%;
          margin-top: 2.5%;
          margin-left: 2.5%;
          margin-right: 2.5%;
          height: 15%;
          p{
            font-size: 0.43rem;
            margin-bottom: 0.6rem;
          }
        }
      }
      .video-article-list-wrapper {
        .videoDisabled {
          height: 17%!important;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {

  .article-content{
    .paywall {
      height: 35%!important;

      p{
        font-size: 0.46rem!important;
      }
    }
    .video-article-list-wrapper {
      .videoDisabled {
        height: 38%!important;
      }
    }
  }

  body {
    main {
      .main-wrapper {
        .container {
        }
      }
      .article-list{
        .paywall {
          width: 95%;
          margin-top: 2.5%;
          margin-left: 2.5%;
          margin-right: 2.5%;
          height: 12%;
          p{
            font-size: 0.33rem;
            margin-bottom: 0.6rem;
          }
        }
      }
      .video-article-list-wrapper {
        .videoDisabled {
          height: 13%!important;
        }
      }

    }
  }
}


@media screen and (max-width: 1024px) {
  .article-142961 {

    .main-wrapper {
      aside {
        display: block;
      }
    }


    .md-col-1,
    .col-xs,
    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12,
    .col-sm,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-md,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-lg,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-xl,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12 {
      float: none;
      width: 100%;
    }
  }
}

