@color_primary: #f10083;
@background_light: #f2f2f2;
@background_dark: #4e4e4e;
@black: #4e4e4e;
@white: white;
@gray: #aaa;

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@font-face {
  //font-family: 'Proxima Nova Alt Rg';
  font-family: "Proxima Nova Alt Rg";
  src: url("/custom/fonts/ProximaNovaA-Regular.eot");
  src: url("/custom/fonts/ProximaNovaA-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/custom/fonts/ProximaNovaA-Regular.woff2") format("woff2"),
    url("/custom/fonts/ProximaNovaA-Regular.woff") format("woff"),
    url("/custom/fonts/ProximaNovaA-Regular.ttf") format("truetype");
  //src: url(https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaBTMnFcQ.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Alt Bold";
  //font-family: 'Proxima Nova Alt Rg';
  src: url("/custom/fonts/ProximaNovaA-Bold.eot");
  src: url("/custom/fonts/ProximaNovaA-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/custom/fonts/ProximaNovaA-Bold.woff2") format("woff2"),
    url("/custom/fonts/ProximaNovaA-Bold.woff") format("woff"),
    url("/custom/fonts/ProximaNovaA-Bold.ttf") format("truetype");
  //src: url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAjsOUYevIWzgPDA.woff2) format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  //font-family: 'Proxima Nova Condensed Bold';
  font-family: "Proxima Nova Condensed Bold";
  src: url("/custom/fonts/Proxima Nova Condensed Black.otf");
  //src: url(https://fonts.cdnfonts.com/css/proxima-nova-condensed) rel="stylesheet";
  font-weight: bold;
  font-style: normal;
}

@font-face {
  //font-family: 'Proxima Nova Condensed';
  font-family: "Proxima Nova Condensed";
  src: url("/custom/fonts/Proxima Nova Condensed Light.otf");
  //src: url(https://fonts.cdnfonts.com/css/proxima-nova-condensed) rel="stylesheet";
  font-weight: normal;
  font-style: normal;
}

@font-face {
  //font-family: 'Proxima Nova Condensed Extrabold';
  font-family: "Proxima Nova Condensed Extrabold";
  src: url("/custom/fonts/Proxima-Nova-Cond-Xbold.otf.otf");
  //src: url(https://fontsfree.net/proxima-nova-condensed-extrabold-font-download.html) rel="stylesheet";
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
    //font-family: 'Apple Symbols';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/AppleSymbols.eot');
    src        : url('/custom/fonts/AppleSymbols.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/AppleSymbols.woff2') format('woff2'),
        url('/custom/fonts/AppleSymbols.woff') format('woff'),
        url('/custom/fonts/AppleSymbols.ttf') format('truetype');
    font-weight: normal;
    font-style : normal;
}



@font-face {
    //font-family: 'Roboto Slab';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/RobotoSlab-Bold.eot');
    src        : url('/custom/fonts/RobotoSlab-Bold.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/RobotoSlab-Bold.woff2') format('woff2'),
        url('/custom/fonts/RobotoSlab-Bold.woff') format('woff'),
        url('/custom/fonts/RobotoSlab-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style : normal;
}

@font-face {
    //font-family: 'SF Pro Text';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/SFProText-Semibold.eot');
    src        : url('/custom/fonts/SFProText-Semibold.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/SFProText-Semibold.woff2') format('woff2'),
        url('/custom/fonts/SFProText-Semibold.woff') format('woff'),
        url('/custom/fonts/SFProText-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style : normal;
}

@font-face {
    //font-family: 'Raleway';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/Raleway-SemiBold.eot');
    src        : url('/custom/fonts/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/Raleway-SemiBold.woff2') format('woff2'),
        url('/custom/fonts/Raleway-SemiBold.woff') format('woff'),
        url('/custom/fonts/Raleway-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style : normal;
}

@font-face {
    //font-family: 'Raleway';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/Raleway-Bold.eot');
    src        : url('/custom/fonts/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/Raleway-Bold.woff2') format('woff2'),
        url('/custom/fonts/Raleway-Bold.woff') format('woff'),
        url('/custom/fonts/Raleway-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style : normal;
}

@font-face {
    //font-family: 'Raleway';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/Raleway-MediumItalic.eot');
    src        : url('/custom/fonts/Raleway-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/Raleway-MediumItalic.woff2') format('woff2'),
        url('/custom/fonts/Raleway-MediumItalic.woff') format('woff'),
        url('/custom/fonts/Raleway-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style : italic;
}

@font-face {
    //font-family: 'Raleway';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/Raleway-Medium.eot');
    src        : url('/custom/fonts/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/Raleway-Medium.woff2') format('woff2'),
        url('/custom/fonts/Raleway-Medium.woff') format('woff'),
        url('/custom/fonts/Raleway-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style : normal;
}

@font-face {
    //font-family: 'SF Pro Text';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/SFProText-Regular.eot');
    src        : url('/custom/fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/SFProText-Regular.woff2') format('woff2'),
        url('/custom/fonts/SFProText-Regular.woff') format('woff'),
        url('/custom/fonts/SFProText-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style : normal;
}

@font-face {
    //font-family: 'Raleway';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/Raleway-ExtraBold.eot');
    src        : url('/custom/fonts/Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/Raleway-ExtraBold.woff2') format('woff2'),
        url('/custom/fonts/Raleway-ExtraBold.woff') format('woff'),
        url('/custom/fonts/Raleway-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style : normal;
}

@font-face {
    //font-family: 'Arial';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/ArialMT.eot');
    src        : url('/custom/fonts/ArialMT.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/ArialMT.woff2') format('woff2'),
        url('/custom/fonts/ArialMT.woff') format('woff'),
        url('/custom/fonts/ArialMT.ttf') format('truetype');
    font-weight: normal;
    font-style : normal;
}*/

/* @font-face {
    //font-family: 'Arial';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/Arial-BoldMT.eot');
    src        : url('/custom/fonts/Arial-BoldMT.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/Arial-BoldMT.woff2') format('woff2'),
        url('/custom/fonts/Arial-BoldMT.woff') format('woff'),
        url('/custom/fonts/Arial-BoldMT.ttf') format('truetype');
    font-weight: bold;
    font-style : normal;
}

@font-face {
    //font-family: 'Frutiger LT';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/FrutigerLT-BlackCn.eot');
    src        : url('/custom/fonts/FrutigerLT-BlackCn.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/FrutigerLT-BlackCn.woff2') format('woff2'),
        url('/custom/fonts/FrutigerLT-BlackCn.woff') format('woff'),
        url('/custom/fonts/FrutigerLT-BlackCn.ttf') format('truetype');
    font-weight: bold;
    font-style : normal;
}

@font-face {
    //font-family: 'Frutiger LT';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/FrutigerLT-BoldCn.eot');
    src        : url('/custom/fonts/FrutigerLT-BoldCn.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/FrutigerLT-BoldCn.woff2') format('woff2'),
        url('/custom/fonts/FrutigerLT-BoldCn.woff') format('woff'),
        url('/custom/fonts/FrutigerLT-BoldCn.ttf') format('truetype');
    font-weight: bold;
    font-style : normal;
}

@font-face {
    //font-family: 'Frutiger LT';
    font-family: 'Proxima Nova Alt Rg';
    src        : url('/custom/fonts/FrutigerLT-BoldCn_1.eot');
    src        : url('/custom/fonts/FrutigerLT-BoldCn_1.eot?#iefix') format('embedded-opentype'),
        url('/custom/fonts/FrutigerLT-BoldCn_1.woff2') format('woff2'),
        url('/custom/fonts/FrutigerLT-BoldCn_1.woff') format('woff'),
        url('/custom/fonts/FrutigerLT-BoldCn_1.ttf') format('truetype');
    font-weight: bold;
    font-style : normal;
}*/

body {
  cursor: auto !important;
}

.csPreloader {
  background: transparent;
}

.amber-text {
  color: @color_primary !important;
}

.btn-amber-text {
  background: none;
  box-shadow: none;
  color: @color_primary !important;

  &:hover {
    box-shadow: none;
  }

  &::after {
    display: none;
  }
}

.pointer {
  cursor: pointer !important;
}

body {
  letter-spacing: 0;
  //font-family     : Helvetica;
  font-family: "Proxima Nova Alt Rg";
  background-color: @background_light;
  color: @black;
  text-shadow: none;

  .unniversimed-container {
    width: 100%;
    max-width: 1280px;
    overflow: visible;

    &.error {
      .navbar {
        .upper-nav {
          background-color: #fff !important;
        }
      }

      main {
        background-color: white;

        .mp-pusher {
          padding-top: 15ex;
          text-align: center;
          background: white;
          font-size: 1.2rem;
          color: #e70480;

          .breadcrumb {
            display: none;
          }
        }
      }

      footer {
        margin-top: 0;
      }
    }

    &.landing {
      .navbar {
        .upper-nav {
          background-color: #fff !important;
        }
      }

      main {
        background-color: white;

        .mp-pusher {
          padding: 25% 0 40%;

          .breadcrumb {
            display: none;
          }
        }
      }
    }
  }
}

main {
  margin: 0;
  padding: 0;

  .container {
    width: 100%;
    padding: 0;
  }
}

.btn {
  //font-family: 'Proxima Nova Alt Rg';
  font-family: "Proxima Nova Alt Rg";
  font-style: normal;
  font-weight: normal;

  &.btn-universimed-portal {
    border-radius: 2em !important;
    padding: 0.6em 1em;
    box-shadow: none;
    background-color: @color_primary !important;
    font-size: 0.7rem;
    min-width: 12em;
    margin: 0;

    &.btn-universimed-portal-outline {
        background  : none !important;
        color       : @black  !important;
        border-color: @black;
        border-width: 1px;
        border-style: solid;
        font-weight : bold;

        &.btn-lang {
            min-width: 4.5em;

            &.active {
                background-color: @color_primary  !important;
                color           : white !important;
                border          : none;
            }
        }
    }

    &.btn-universimed-portal-yellow-outline {
      background  : white !important;
      color       : @black  !important;
      border-color: @color_yellow;
      border-width: 1px;
      border-style: solid;
      font-weight : bold;

      &:hover {
        background  : white !important;
      }

      &.btn-lang {
          min-width: 4.5em;

          &.active {
              background-color: @color_yellow  !important;
              color           : @black !important;
          }
      }

      &.btn-lang-fr {
        margin-right: 32px;
      }
    }

    &.btn-universimed-portal-flag {
      background: none !important;
      color: @black !important;
      border: 0;
      border-style: solid;
      font-weight: bold;
      padding: 0.6em 0;
      min-width: auto;
      text-align: right;
      margin-right: 12px;
    }
  }

  &.btn-primary {
    background: none;
    color: @black !important;
    box-shadow: none;

    &:after {
      content: none;
    }

    &:hover {
      background: none !important;
    }
  }
}

.fa.fa-bookmark {
  color: @color_primary !important;
}

.form-input {
  border: none !important;
  background-color: @background_light !important;
  border-radius: 2em !important;
  color: @black !important;
  padding-left: 1em;
  padding-right: 1em;

  ::-webkit-input-placeholder {
    color: @black !important;
  }

  :-ms-input-placeholder {
    color: @black !important;
  }

  ::placeholder {
    color: @black !important;
  }
}

.imageGradient {
  &:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    background-image: linear-gradient(
      180deg,
      fade(@background_light, 0%) 0%,
      fade(@white, 85%) 45%,
      fade(@white, 90%) 99%
    );
  }
}

.more {
  // color: @color_primary !important;
  // text-transform: uppercase;
  // font-size: 0.8rem;
  // //font-family   : 'Proxima Nova Alt Bold';
  // font-family: "Proxima Nova Alt Rg";
  // font-style: normal;
  // font-weight: bold;
  color: #984398 !important;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-family: "Proxima Nova Alt Rg";
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  letter-spacing: 0.1em;

  &.kursinformation {
    font-size: 0.9rem;
  }
}

.back-to-top {
  text-transform: uppercase;
  font-size: 1.1rem;
  //font-family   : 'Proxima Nova Alt Bold';
  font-family: "Proxima Nova Alt Rg";
  font-style: normal;
  font-weight: bold;
  float: none;
  letter-spacing: 0.1em;

  i {
    font-size: 1.2rem;
  }

  background: white;
  border: solid 1px rgb(0, 0, 0);
  border-radius: 1em;
  position: fixed;
  bottom: 35px;
  right: 30px;
  z-index: 99;
  padding-left: 0.8em;
  padding-right: 0.5em;
}

.breadcrumb {
  //font-family: 'Proxima Nova Alt Bold';
  font-family: "Proxima Nova Alt Rg";
  font-style: normal;
  font-weight: bold;
  background: none;
  margin-left: 1.5em;
  font-size: 0.7rem;

  &.long_breadcrumb {
    margin-top: 120px;
    margin-bottom: 5px;
    margin-left: 11rem;
  }
}

.navbar-fixed-top {
  .container {
    width: 80%;
    max-width: 1280px;

    .navbar {
      border-bottom-left-radius: 1em;
      border-bottom-right-radius: 1em;
      padding: 0;
      box-shadow: none;

      &.navbar-dark {
        background: @white;

        .upper-nav {
          background-color: @background_light;
          margin: 0;
          padding: 0.3em 1em;

          .links {
            .link {
              margin-right: 1em;
              height: auto;
              width: auto;
              max-width: 7em;
              max-height: 1.5em;
              min-height: 0.7em;

              img {
                path {
                  fill: "currentColor";
                }
              }

              &.urologik {
                max-height: 0.1em;
                //opacity: 0.6;
              }

              &.meddiplom {
                width: 5.5em;
              }

              &.leading {
                max-height: 1.7em;
              }
            }
          }

          .right {
            padding: 0;

            .customQuickbar {
              margin: 0;
              padding: 0;
              border: none;
              float: right;
              margin-right: 1em;

              .quickbar-item {
                display: inline;
                margin-left: 0.5em;

                a {
                  //font-family: 'Proxima Nova Alt Bold';
                  font-family: "Proxima Nova Alt Rg";
                }
              }

              .dropdown-menu {
                .dropdown-item {
                  //font-family: 'Proxima Nova Alt Rg';
                  font-family: "Proxima Nova Alt Rg";
                }
              }
            }
          }
        }

        .bottom-nav {
          margin: 0;
          padding: 0 1em;
          margin-top: 2ex;
          margin-bottom: 2ex;

          .bookmark-wrapper {
            i.fa-bookmark {
              margin-left: 15px;
              padding-top: 1px;
            }
          }

          .search {
            .btn-universimed-portal {
              padding: 0.75rem 0.5rem;
            }
          }

          .mainNav {
            background: @white;
            box-shadow: none;

            .btn {
              font-weight: bold;
              // padding      : 0.858rem;  (old version)
              padding: 0.858rem 10px 0.858rem 10px;
              //font-family: 'Roboto Slab';
              font-family: "Proxima Nova Alt Rg";

              &.fachthemen:focus {
                background-color: transparent !important;
                color: @color_primary !important;
              }
            }

            .open > .btn-primary.dropdown-toggle,
            .btn-primary:focus {
              background-color: transparent !important;
              color: @color_primary !important;
            }
          }

          .navbar-brand {
            width: 70%;
          }
        }

        .navbar-brand {
          width: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }

        .search {
          display: none;

          form {
            width: 80%;

            .form-input {
              width: 60%;
            }
          }
        }

        .fa {
          cursor: pointer;
          float: right;
          margin-top: 1ex;

          &.navigation {
            color: @color_primary;
          }
        }
      }
    }
  }
}

#slider-article-slide {
  img {
    &.img-fluid {
      border-radius: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .carousel-item {
    height: 830px;
  }

  .carousel-caption {
    text-align: left;
    left: 12%;
    bottom: 10%;
    line-height: 3.5em;
    font-size: 1rem;
    letter-spacing: -0.13px;
    text-shadow: none;
    color: @black;

    .teaserStrapLine {
      font-size: 1rem;
      //font-family: 'Proxima Nova Alt Bold';
      font-family: "Proxima Nova Alt Rg";
      font-style: normal;
      font-weight: normal;
      margin-top: 20px;

      .category,
      .subcategory,
      .survey-info {
        text-decoration: underline;
      }
    }

    .more {
      font-size: 0.8rem;
      color: @white !important;
      margin-top: 10ex;
    }

    h4 {
      font-size: 3.5rem;
      //font-family: 'Raleway';
      font-family: "Proxima Nova Alt Rg";
      font-style: normal;
      font-weight: 700;
      line-height: 1.2em;

      &:after {
        border: none;
      }
    }
  }

  .slider-articles-teaser {
    position: absolute;
    background-color: @white;
    width: 57%;
    bottom: 0;
    right: 0;
    padding: 2ex 3em;
    border-top-left-radius: 1em;
    min-height: 17ex;

    .teaserStrapLine {
      font-size: 0.8125rem;
      display: block;
      //font-family: 'Proxima Nova Alt Rg';
      font-family: "Proxima Nova Alt Rg";
    }

    .teaserCategories {
      font-size: 0.625em;
      text-decoration: underline;
      display: block;

      //font-family: 'Proxima Nova Alt Bold';
      font-family: "Proxima Nova Alt Rg";
      font-style: normal;
      font-weight: normal;
    }

    .teaserTitle {
      // margin-top   : 1ex;
      margin-bottom: 1ex;
      display: block;

      //font-family: 'Proxima Nova Alt Bold';
      font-family: "Proxima Nova Alt Rg";
      font-style: normal;
      font-weight: bold;
    }
  }

  .carousel-indicators-fachthemen {
    bottom: 0 !important;
  }

  .carousel-inner-fachthemen {
    // width       : 87.5%;
    margin-left: auto;
    margin-right: auto;
  }

  &.fachthemen-slider {
    .slider-articles-teaser {
      margin-right: 5.7em;
    }

    .imageGradient {
      .kursart {
        position: absolute;
        top: 2ex;
        right: -1em;
        background: @color_primary;
        margin-right: 15px;
        padding: 0.5ex 1em;
        // border-top-right-radius: 0.5em;
        color: white;
        text-align: center;
      }

      .points {
        position: absolute;

        padding: 0.5ex 1em;
        color: @color_primary;

        top: 0;
        left: 0;
        background: #bababa;
        margin-right: 15px;
        border-top-left-radius: 0.5em;
        color: white;
        text-align: center;
        //font-family           : 'Proxima Nova Alt Bold';
        font-family: "Proxima Nova Alt Rg";
      }

      .pass {
        position: absolute;
        top: 2ex;
        left: 0;

        padding: 0.5ex 1em;
        color: green;
        text-align: center;
        //font-family: 'Proxima Nova Alt Bold';
        font-family: "Proxima Nova Alt Rg";

        top: 0;
        left: 0;
        background: #bababa;
        margin-right: 15px;
        border-top-left-radius: 0.5em;

        span {
          vertical-align: top;
        }

        .fa-stack {
          height: 1em;

          .fas,
          .far {
            font-size: 1.5rem;
          }

          .fa-check {
            left: 0.15em;
            bottom: -0.4ex;
          }
        }
      }
    }
  }
}

.slider-french-articles {
  #slider-article-slide {
    .carousel-item {
      height: 81.2ex;
    }
  }
}

.topic-event-article,
.csWidget-wrapper {
  h3,
  h4 {
    //font-family    : 'Proxima Nova Alt Bold';
    font-family: "Proxima Nova Alt Rg";
    font-style: normal;
    font-weight: bold;
    margin: 0;
    text-decoration: underline;
    font-size: 1.2rem;
    line-height: 1.5em;
    margin-bottom: 1ex;
  }
  h3.title{
    font-size: 35px !important;
    margin-left: 0 !important;
    font-weight: 1000 !important;
    margin-bottom: 1.5ex;
    margin-top: 1.5ex;
  }

  .imageGradient,
  article {
    .promotion {
      top: 0;
      position: absolute;
      right: 15px;
      background: @color_primary;
      color: #fff;
      border-bottom-left-radius: 12px;
      width: 90px;
      height: 25px;
      text-align: center;
      padding-left: 4px;
      //font-family              : 'Proxima Nova Alt Rg';
      font-family: "Proxima Nova Alt Rg";
    }
    .video {
      top: 0;
      position: absolute;
      //left: 0px;
      background: #f10083;
      color: #fff;
      border-bottom-right-radius: 12px;
      width: 90px;
      height: 25px;
      text-align: center;
      padding-right: 4px;
      font-family: "Proxima Nova Alt Rg";
    }

    p {
      //font-family: 'Proxima Nova Alt Rg';
      font-family: "Proxima Nova Alt Rg";
      font-style: normal;
      font-weight: normal;
      font-size: 0.9rem;
    }

    h4 {
      margin: 0;
      padding: 0;
      width: 100%;
      text-decoration: none;
      font-size: 1.3rem;
      line-height: 1.6rem;
      margin-bottom: 1ex;
    }

    .category,
    .subcategory,
    .survey-info {
      //font-family    : 'Proxima Nova Alt Rg';
      font-family: "Proxima Nova Alt Rg";
      font-style: normal;
      font-weight: normal;
      font-size: 0.7rem;
      text-decoration: underline;
    }
  }
}

img {
  &.style-width-200.img-fluid{
    width: 200px;
  }

  &.img-fluid {
    border-radius: 0.5em;
    margin-bottom: 2ex;


    &.headImage {
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}

.main-stage-articles {
  width: 80%;
  max-width: 1280px;

  .right-list {
    article {
      border-top: 1px solid @background_350;
      padding-top: 2ex;

      &:first-child {
        border-top: none;
        padding-top: 0ex;
      }
      .teaserTitle{
        h4{
          margin: 0;
          padding: 0;
          width: 100%;
          text-decoration: none;
          font-size: 1.3rem;
          margin-bottom: 1ex;
          font-weight: bold;
          line-height: 1.6rem;
        }
      }
    }
  }

  article {
    max-width: unset !important;
    img.img-fluid{
      cursor: pointer !important;
    }
  }
}

.video-articles {
  article {
    max-width: unset !important;
  }

  .article-list {
    position: relative;
  }

  .main-article {

    article{



    }


    .teaserStrapLine {
      margin-top: 1em;
      margin-bottom: 0.2em;
    }

    .teaserTitle {
      margin-bottom: 0.5rem;
    }
  }

  .video-list-right {
    overflow: auto;
    height: 70ex;
    background-color: @background_250;

    article {
      border-top: 1px solid @background_350;
      padding-top: 2ex;

      .teaserTitle {
        font-size: 18px;
      }

      &.active {
        border-left: solid 4px @color_primary_700;
        background-color: @background_350;
      }

      &:first-child {
        border-top: none;
        padding-top: 0ex;
      }
    }
  }
}

.facthemen-articles {
  position: relative;
  margin-top: 2ex;

  .background-full {
    background-color: @background_250;
    position: absolute;
    width: 150vw;
    height: 105%;
    left: -25vw;
    z-index: -1;
  }

  .article-list-wrapper {
    width: 100%;
  }
}

.newest-articles,
.video-articles,
.main-stage-articles,
.most-read-articles,
.author-articles {
  margin: auto;
  margin-top: 7ex;

  .article-list {
    h4 {
      // margin-left: 15px;
      // width: 5em;
      margin: 0;
      padding: 0;
      width: 100%;
      text-decoration: none;
      font-size: 1.3rem;
      margin-bottom: 1ex;
      line-height: 1.6rem;

      &.title {
        width: auto;
        //font-family: 'Roboto Slab';
        font-family: "Proxima Nova Alt Rg";
      }

      @media screen and (min-width: 1280px) and (max-width: 2000px) {
        font-size: 1rem;
      }

      @media screen and (min-width: 1024px) and (max-width: 1280px) {
        font-size: 0.75rem;
        padding-right: 2px;
        word-break: break-word;
      }
    }

    article {
      h4 {
        // margin: 0;
        // width: 100%;
        margin: 0;
        padding: 0;
        width: 100%;
        text-decoration: none;
        font-size: 1.3rem;
        margin-bottom: 1ex;
        line-height: 1.6rem;
      }

      .rating {
        display: inline-block;
        font-size: 0.8rem;
      }

      .bookmark {
        float: right;
        margin-right: 0.2ex;
        font-size: 1.5rem;
        color: @gray;
        font-weight: 800;
      }
    }

    .imageGradientSize {
      .imageGradientText {
        position: absolute;
        width: 70%;
        bottom: 5ex;
        margin-left: 1em;

        h1 {
          //font-family: 'Proxima Nova Alt Bold';
          font-family: "Proxima Nova Alt Rg";
          font-style: normal;
          font-weight: bold;

          font-size: 2rem;

          @media screen and (min-width: 1024px) and (max-width: 1280px) {
            font-size: 1rem;
          }
        }

        .bookmark {
          float: none;
          margin-left: 1em;
          position: absolute;
          top: 2px;
        }
      }
    }
  }
}

.row.rating-wrapper {
  margin-bottom: 10px;
}

.most-read-articles {
  // padding   : 7ex 10%;
  background: @white;
  margin-top: 10ex;

  h3.title{
    margin-left: -6px !important;
  }
  .title {
    //font-family: 'Roboto Slab';
    font-family: "Proxima Nova Alt Rg";
  }

  .carousel-indicators {
    bottom: -6ex;

    li {
      background: @gray;

      &.active {
        background: @color_primary;
      }
    }
  }

  .article-list {
    width: 100%;

    .article-list-wrapper {
      width: 100%;
    }
  }
}

.switch-land-selector-container {
  display: inline;

  .csSnippet,
  .land-selector-container {
    display: inline;
  }
}

.switch-land {
  text-align: center;

  .flags {
    display: inline-block;
    width: 298px;
    height: 174px;
    padding: 0 50px;

    &.flagText {
      height: auto;
      width: 65%;
    }

    .label {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1rem;
      width: 100%;
      float: left;
      line-height: 25px;
      padding-bottom: 30px;
    }

    .flag-container {
      width: 50%;
      float: left;
      font-weight: 600;
      cursor: pointer;
      margin-bottom: 15px;

      .flag-icon {
        line-height: 3em;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

&.error,
&.landing {
  footer {
    //position    : absolute;
    bottom: 0;
    padding-bottom: 10px;
    margin-top: 0;

    &.page-footer {
      padding: 2ex 0;
    }

    ul {
      li {
        float: left;
        text-transform: capitalize;
        margin-right: 30px;
      }
    }

    .container {
      .copyright {
        margin-top: 0;
      }
    }
  }
}

.csArticle-page_main {
  padding-top: 2em;
  &:before {
    content: " ";
    background: @color_primary;
    height: 2px;
    width: 6em;
    position: absolute;
    top: 0;
  }
}

.topic-event-article {
  &:before {
    content: "";
    display: none;
  }
}

.article-header {
  position: relative;
  margin: 0;


  .container{
    margin-top: 25px;
  }

  .teaserStrapLine {
    margin-top: 2ex;
  }

  .imageGradient {
    &:after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;

      background-image: linear-gradient(
        180deg,
        fade(@background_light, 0%) 1%,
        fade(@background_light, 85%) 49%,
        fade(@background_light, 90%) 100%
      );
    }

    .emptyHead {
      min-height: 30rem;
      margin-bottom: -30px;
    }

    img {
      &.img-fluid {
        border-radius: 0 !important;
        margin-bottom: 0 !important;
      }
    }

    .source {
      position: absolute;
      bottom: 0;
      left: 1em;
      z-index: 999;
      color: #999999;
    }
  }

  .info-wrapper {
    position: relative;
    //bottom: 5ex;
    width: 100%;

    .container {
      //width: 50%;
      margin: auto;

      color: black;

      &.altColor {
        color: white;
      }

      .h1-responsive {
        // font-family: 'Proxima Nova Alt Bold';
        font-family: "Proxima Nova Alt Rg";
        font-style: normal;
        font-weight: bold;
        font-size: 3rem;
      }

      .subtext {
        //font-family: 'Proxima Nova Alt Rg';
        font-family: "Proxima Nova Alt Rg";
        font-style: normal;
        font-weight: normal;

        font-size: 0.8rem;
      }

      .articleRating {
        font-size: 1rem;

        display: inline;
        margin-left: 2em;

        .rating {
          display: inline;

          .active-rating {
            color: @color_primary;
          }
        }
      }

      .promotion {
        color: white;
        width: 7.7em;
        background: @color_primary;
        padding: 0.3ex 0.4em;
        text-align: center;
        border-bottom-left-radius: 1em;
        margin-bottom: 1ex;
      }
    }
  }
}

.related-articles {

  width: 1280px;
  max-width: 100vw;
  /* background: white; */
  margin-left: -275px;

  .subtext {
    padding: 0;
    //font-family: 'Proxima Nova Alt Rg';
    font-family: "Proxima Nova Alt Rg";
    font-style: normal;
    font-weight: normal;

    font-size: 0.7rem;

    text-transform: uppercase;
  }
}

.articleRating {
  margin-bottom: 0;

  img.rate_star {
    width: 12px;
    height: 12px;
    display: inline-block;
  }

  i.fa-star-half {
    position: absolute;
    bottom: 5px;
  }

  .rating_sum {
    font-size: 11px;
    color: #999;

    &.rated {
      color: @color_primary;
    }
  }

  .rating {
    display: inline;
  }

  ul.rating.rating-active {
    &:hover {
      li {
        color: @color_primary;
      }
    }

    li:hover {
      color: @color_primary;
    }

    li:hover ~ li {
      color: inherit;
    }
  }
}

.article-content {
  margin-top: 2ex;

  .teaserTitle {
    margin-bottom: 2.5ex;
  }

  &:before {
    border: none !important;
  }

  .article-content-main {
    margin: 0;
    padding-left: 6ex;
    padding-right: 6ex;

    //font-family: 'Proxima Nova Alt Rg';
    font-family: "Proxima Nova Alt Rg";
    font-style: normal;
    font-weight: normal;

    .stage-articles {
      width: 130%;
      margin-left: -15%;
      margin-top: -11ex;
      margin-bottom: 4ex;
    }
  }

  .sidebar {
    margin: 0;

    &:before {
      content: none;
    }
  }

  .articleMediaButtons {
    padding: 0;
    font-size: 1.3rem;
    color: #aaa;
    text-align: right;


    .buttons {
      margin: auto;
      margin-right: -0.4em;

      span {
        margin: 0 0.1em;
        vertical-align: text-top;
      }

      .merken-text {
        font-size: 0.9rem;
        top: 4px;
        position: relative;
      }

      .merken {
        display: inline-block;
        cursor: pointer;
      }
    }

    .shariff {
      .shariff-button {
        width: 9em;
        .share_text {
          margin-left: 0.5em;
        }
      }
    }
  }

  .share-mail-article,
  .reading-time-article {
    margin-top: 5ex;

    .share-mail-wrapper,
    .time-wrapper {
      color: #999999;
      border: 1px solid #999999;
      border-radius: 2em;
      padding: 1ex 1em;

      i {
        margin-right: 0.3em;
      }
    }
  }

  .authors-article {
    a {
      &.btn {
        min-width: 160.72px;
        font-size: 16px;
        padding: 1ex 1em;
      }
    }

    .kursImage {
      width: 71%;
    }
  }

  .share-mail-article {
    .share-mail-wrapper {
      cursor: pointer;
    }
  }

  .share-mail-article,
  .reading-time-article,
  .date-article,
  .authors-article {
    padding: 0;
    text-align: right;
    margin-bottom: 1ex;

    .btn {
      margin-top: 2ex;
      margin-bottom: 2ex;
    }

    .pubdate,
    .author {
      margin-right: 0;
      margin-top: 2ex;
      margin-left: 1em;
      //font-family : 'Proxima Nova Alt Rg';
      font-family: "Proxima Nova Alt Rg";
      font-style: normal;
      font-weight: normal;
      font-size: 0.9rem;

      .name,
      .date {
        &.title {
          //font-family: 'Proxima Nova Alt Bold';
          font-family: "Proxima Nova Alt Rg";
          font-style: normal;
          font-weight: bold;
        }
      }

      .brand {
        text-transform: uppercase;
        font-size: 16px !important;
      }

      .promotion {
        width: 13.5em;
		    float: right;
		    padding: 5px;
		    border-radius: 40px;
		    font-size: 0.75rem;
		    border-width: 1px;
		    border-color: white;
		    margin: 12px 0px 11px;
		    color: white;
		    border-style: solid;
		    background-color: #4c224c;
		    height: 31px;
		    text-align: center;
      }
    }

    .oldData {
      //word-wrap: break-word;

      .author,
      .text {
        display: block;
        margin-top: 0;
        white-space: pre-wrap;
      }
    }
  }
}

.land-selector {
  .dropdown-menu {
    text-align: center;
    min-width: 8rem;
  }
}

.theme_list {
  .article-list {
    .articleElement {
      .teaser-chips-wrapper{
        padding: 0 12px 0 12px;

        .teaser-chips {
          margin-left: 0;
        }

        .kursart {
          bottom: 0;
          right: 125px;
          background: @color_primary_900;
          font-size: 0.7rem;
          border-radius: 40px;
          border-width: 1px;
          border-color: white;
          margin: 11px 0px 10px 0px;
          border-style: solid;
          height: 24px;
          float: left;
          color: white;
          padding: 0.5ex 1em;
          text-align: center;
        }

        .points {
          padding: 0.5ex 1em;
          bottom: 0;
          right: 0;
          background: @color_primary_900;
          font-size: 0.7rem;
          border-radius: 40px;
          border-width: 1px;
          border-color: white;
          margin: 11px 0px 10px 0px;
          border-style: solid;
          height: 24px;
          float: left;
          color: white;
          padding: 0.5ex 1em;
          text-align: center;
          font-family: "Proxima Nova Alt Rg";

          &.fr {
            background-color: @color_yellow;
            color           : @black;
          }
        }
      }
      a {
        .pass {
          position: absolute;
          top: 0;
          left: 0;

          padding: 0.5ex 1em;
          color: green;
          text-align: center;
          //font-family: 'Proxima Nova Alt Bold';
          font-family: "Proxima Nova Alt Rg";

          background: #bababa;
          margin-right: 15px;
          border-top-left-radius: 0.5em;

          span {
            vertical-align: top;
          }

          .fa-stack {
            height: 1em;

            .fas,
            .far {
              font-size: 1.5rem;
            }

            .fa-check {
              left: 0.15em;
              bottom: -0.4ex;
            }
          }
        }
      }
    }
  }
}

.theme_list {
  //font-family: 'Proxima Nova Alt Rg';
  font-family: "Proxima Nova Alt Rg";
  font-style: normal;

  .articles {
    // margin-top: 10px;
    clear: both;

    .article {
      .image {
        .kursart {
          position: absolute;
          top: 0;
          right: 0;
          background: @color_primary;
          margin-right: 15px;
          padding: 0.5ex 1em;
          border-top-right-radius: 0.5em;
          color: white;
          text-align: center;
        }

        .points {
          position: absolute;

          padding: 0.5ex 1em;
          color: @color_primary;

          top: 0;
          left: 0;
          background: #bababa;
          margin-right: 15px;
          border-top-left-radius: 0.5em;
          color: white;
          text-align: center;
          //font-family           : 'Proxima Nova Alt Bold';
          font-family: "Proxima Nova Alt Rg";
        }

        .pass {
          position: absolute;
          top: 0;
          left: 0;

          padding: 0.5ex 1em;
          color: green;
          text-align: center;
          //font-family: 'Proxima Nova Alt Bold';
          font-family: "Proxima Nova Alt Rg";

          background: #bababa;
          margin-right: 15px;
          border-top-left-radius: 0.5em;

          span {
            vertical-align: top;
          }

          .fa-stack {
            height: 1em;

            .fas,
            .far {
              font-size: 1.5rem;
            }

            .fa-check {
              left: 0.15em;
              bottom: -0.4ex;
            }
          }
        }
      }
    }

    .list,
    .grid {
      article {
        .teaserStrapLine {
          font-size: 0.8125rem;
          margin-bottom: 0;
          padding-right: 10px;
        }

        .title {
          margin-bottom: 0;
          padding-right: 10px;
        }

        .title,
        .date {
          font-weight: bold;
        }

        .date {
          font-size: 0.625rem;
        }

        .category,
        .subcategory,
        .survey-info {
          font-size: 0.625rem;
        }

        .rating {
          font-size: 0.8rem;
          display: inline;
        }

        .rating_sum {
          margin-left: 5px;
          margin-top: 10px;
          position: relative;
          top: 1px;
          color: #999;

          &.rated {
            color: @color_primary;
          }
        }

        .teaser {
          font-size: 0.875rem;
          margin-right: 1em;
        }

        .bookmarkLabel {
          //font-family: 'Raleway';
          font-family: "Proxima Nova Alt Rg";
          font-size: 0.75rem;
        }

        .bookmark {
          font-size: 1.1rem;
          top: 4px;
          position: relative;
          margin-left: 10px;
        }
      }
    }

    .float-right {
      text-align: right;
      padding-right: 40px;
    }

    .list {
      .grid_view {
        display: none;
      }
    }

    .grid {
      margin-bottom: 30px;

      .list_view {
        display: none;
      }
    }
  }

  form {
    .buttons {
      padding-right: 15px;

      button {
        background: transparent;
        border: 0;
        top: 10px;
        position: relative;
        cursor: pointer;

        &.selected {
          color: @color_primary;
        }
      }
    }

    div.align-right,
    select.align-right {
      float: right;
    }

    .select-wrapper {
      background-color: white;
      border-radius: 1em;
      padding: 0 20px;
      max-width: 45%;
      min-width: 40%;
      float: left;
      margin-right: 20px;
      cursor: pointer;

      ul {
        max-width: 100%;
      }

      &.long {
        max-width: 90%;
        min-width: 50%;
      }

      &.selected {
        border: 1px @color_primary solid;
        min-height: 40px;

        .custom_container {
          float: left;
          width: 110%;

          span.custom_label {
            margin-right: 10px;
            padding-top: 8px;
            float: left;

            &.more {
              top: 3px;
              position: absolute;
            }
          }
        }
      }

      input[type="text"] {
        margin-bottom: 0;
        border-bottom: none;
        max-height: 40px;
        width: 120%;
        left: -1.3em;
        padding-left: 1.3em;
        background-color: @background_light;
        border-radius: 2em;
      }

      span.caret {
        right: 16px;
        font-size: 0;
        top: 14px;
        z-index: 9;

        &::before {
          font-family: FontAwesome;
          // font-family: 'Proxima Nova Alt Rg';
          content: "\f078";
          font-size: 10px;
        }
      }
    }

    input[type="checkbox"] + label {
      top: 2px;
    }

    input[type="checkbox"]:checked + label {
      background: @color_primary;
      width: 20px;
      height: 20px;
      padding-left: 0;
      margin-right: 13px;
      border-radius: 0.3em;
      margin-bottom: -2px;

      &::before {
        top: 0;
        left: 1px;
        width: 8px;
        height: 13px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        -webkit-transform: rotateZ(37deg);
        transform: rotateZ(37deg);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
      }
    }

    .rubrics_select {
      ul {
        min-width: auto;
        max-width: none;
        // width     : 562px !important;
        width: 42em !important;
        min-height: auto;
        max-height: none;
        height: 195px;
        letter-spacing: normal;

        li {
          float: left;
          // min-width: 180px;
          min-width: 13em;

          span {
            // font-size: 0.7rem;
            font-size: 0.8rem;
            padding: 0.2rem 0.2rem;

            input[type="checkbox"] + label {
              padding-left: 0;
            }

            input[type="checkbox"]:checked + label {
              width: 12px;
              height: 12px;
              margin-right: 8px;
            }

            input[type="checkbox"]:checked + label::before {
              top: -2px;
              left: 0px;
              width: 6px;
              height: 10px;
            }

            label {
              height: 12px;
              width: 12px;
              margin-right: 8px;
              border-radius: 0.3em;
              margin-bottom: -2px;

              &::before {
                width: 12px;
                height: 12px;
                top: -4px;
                border-radius: 0.2em;
                border-width: 1px;
              }
            }
          }

          &.hideCheckbox {
            [type="checkbox"] + label:before {
              border: none;
            }

            &:hover {
              background-color: white;
            }
          }
        }
      }
    }

    .closeButton {
      position: relative;
      top: 2.5rem;
      // left    : 14.5rem;
      left: 21rem;
      z-index: 999999;
      display: none;

      &.bottom {
        position: absolute;
        top: 13rem;
        // left    : 14.5rem;
        // left       : 15.5rem;
        z-index: 999999;
        display: none;
        right: 0;
        left: auto;
      }
    }
  }

  ul.rubrics_selected {
    padding-left: 15px;
    padding-right: 15px;

    li {
      display: inline-block;
      background-color: @gray;
      padding: 5px 15px 5px 20px;
      margin-right: 10px;
      border-radius: 1em;
      cursor: pointer;
      font-size: 14px;
      margin-bottom: 10px;

      span {
        margin-right: 15px;
      }
    }
  }
}

.fachthemen.floatMenu {
  position: relative;
  top: 5px;
  display: none;

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid white;
    margin-left: 5em;
  }

  fieldset {
    background: white;
    border-radius: 1em;
    padding: 15px;

    .form-group {
      margin-bottom: 0;

      input[type="checkbox"].filled-in:checked + label:after {
        background-color: @color_primary;
        border-color: @color_primary;
      }

      input[type="checkbox"] + label {
        padding-left: 25px;
        font-size: 0.8rem;

        &::before {
          top: 2px;
        }

        &::after {
          top: 2px;
          border-radius: 0.4em;
          border-width: 1px;
        }
      }
    }

    .buttons {
      padding: 20;

      .btn.btn-universimed-portal {
        float: right;
        margin: 0 10px;
        min-width: 16em;
        margin: 0 10px;
        padding: 1em 3em;

        &.gray {
          background-color: @gray !important;
        }
      }
    }
  }
}

@media screen and (max-width: 766px) {
  .search-articles {
    padding: 0 5ex !important;
  }
}

.search-articles {
  margin: auto;
  width: 80%;
  max-width: 1280px;
  padding: 0;

  form {
    input[type="search"] {
      background: #fff;
      max-width: 20em;
      border-radius: 6em;
      padding-left: 20px;
      padding-right: 20px;
    }

    input[type="search"] + label {
      padding-left: 20px;

      &.active {
        -webkit-transform: translateY(-175%);
        -moz-transform: translateY(-175%);
        -ms-transform: translateY(-175%);
        -o-transform: translateY(-175%);
        transform: translateY(-175%);
      }
    }

    input[type="search"]:focus:not([readonly]) {
      border-bottom: 1px solid @color_primary;
      -webkit-box-shadow: 0 1px 0 0 @color_primary;
      -moz-box-shadow: 0 1px 0 0 @color_primary;
      box-shadow: 0 1px 0 0 @color_primary;
    }

    input[type="search"]:focus:not([readonly]) + label {
      color: @color_primary;
    }
  }

  h3 {
    justify-content: center;
    display: flex;
    text-decoration: none;
    color: #652d65;
    font-size: 24px;
    margin-bottom: 0;
  }

  .article-list {
    padding-left: 15px;

    .image {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

  .results {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .total_results {
      margin-left: 5px;
    }
  }

  .js-filter-replace {
    display: none;
  }

  article {
    max-width: unset !important;
    margin-top: 3ex;

    .teaserStrapLine {
      font-size: 0.8125rem;
    }

    .title,
    .date {
      font-weight: bold;
    }

    .date {
      font-size: 0.625rem;
    }

    .category,
    .subcategory,
    .survey-info {
      font-size: 0.625rem;
    }

    .rating {
      font-size: 0.8rem;
      display: inline;
      margin-right: 5px;
    }

    .rating_sum {
      position: relative;
      top: 1px;
    }

    .teaser {
      font-size: 0.875rem;
    }

    .bookmarkLabel {
      //font-family: 'Raleway';
      font-family: "Proxima Nova Alt Rg";
      font-size: 0.75rem;
    }

    .bookmark {
      font-size: 1.1rem;
      position: relative;
      top: 2px;
      margin-left: 5px;
    }
  }
}

.articleInfo {
  font-size: 0.9rem;
}

.authors-article {
  font-size: 0.8rem;
  //font-family: Proxima Nova Alt Rg;
  font-family: "Proxima Nova Alt Rg";

  .kursImage {
    width: 45%;
    margin-right: 0;
    margin-left: auto;
    margin-top: 3ex;
  }

  .group {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 25px;

    a {
      border-radius: 2em !important;
      padding: 0.6em 1em;
      box-shadow: none;
      background-color: @color_primary !important;
      font-size: 0.7rem;
      min-width: 12em;
      margin: 0;
      color: white;
      float: right;

      i.fa-external-link {
        display: none;
      }
    }
  }
}

.html-string {
  img {
    max-width: 100%;
    height: auto;
    border-radius: 0.5em;
  }

  .literatur {
    width: 100%;
    border-top: solid 2px #dddddd;
    padding-top: 1ex;
    display: block;
    color: #aaa;
    margin-top: 10ex;
    margin-bottom: 2ex;
    font-size: 0.9rem;

    &:before {
      content: ">";
      margin-right: 1em;
      margin-left: 0.5em;
    }
  }
}

#keypoints {
  ul {
    margin-left: 2em;

    li {
      list-style-type: initial;
    }
  }
}

.main_banner {
  max-width: 160px;
}

.epapers_list {
  h3 {
    justify-content: center;
    display: flex;
    text-decoration: none;
    color: @color_primary !important;
    font-size: 24px;
    margin-bottom: 20px;
    //font-family    : 'Proxima Nova Alt Bold';
    font-family: "Proxima Nova Alt Rg";
  }

  .covers {
    padding-top: 25px;
    display: inline-block;

    .cover {
      margin-bottom: 25px;
      display: inline-block;

      .image,
      .name,
      .date {
        display: flex;
        justify-content: center;
      }

      .image {
        img {
          width: auto;
          height: 300px;
          border-radius: 0;
          margin-bottom: 1ex;
        }
      }

      .name {
        font-size: 1.2rem;
        font-weight: 900;
        margin-bottom: 5px;
      }

      .date {
        font-size: 0.75em;
      }
    }
  }

  .covers-flex {
    padding-top: 25px;
    display: flex;
    width: 100%;

    .cover {
      margin-bottom: 25px;
      display: flex;
      min-height: 440px;

      a {
        width: 100%;
      }

      .image,
      .name,
      .date {
        display: flex;
        justify-content: center;
      }

      .image {
        img {
          width: auto;
          height: 300px;
          border-radius: 0;
          margin-bottom: 1ex;
        }
      }

      .name {
        font-size: 1.2rem;
        font-weight: 900;
        margin-bottom: 5px;
      }

      .date {
        font-size: 0.75em;
      }
    }
  }

  form {
    div.align-right,
    select.align-right {
      float: right;
    }


    .select-wrapper {
      background-color: white;
      border-radius: 1em;
      padding: 0 20px;
      max-width: 50%;
      min-width: 40%;
      float: left;
      margin-right: 20px;
      cursor: pointer;

      &.epaper-datefilter-select {
        max-width: 60%;
      }

      &.epaper-filter-select {
        width: 30%;
        max-width: inherit;
        min-width: inherit;
      }

      ul {
        max-width: 100%;
      }

      &.long {
        max-width: 60%;
        min-width: 50%;
      }

      &.selected {
        border: 1px @color_primary solid;
        min-height: 40px;

        .custom_container {
          float: left;
          width: 100%;

          span.custom_label {
            margin-right: 10px;
            padding-top: 8px;
            float: left;

            &.more {
              top: 3px;
              position: absolute;
            }
          }
        }
      }

      input[type="text"] {
        margin-bottom: 0;
        border-bottom: none;
        max-height: 40px;
      }

      span.caret {
        right: 16px;
        font-size: 0;
        top: 14px;

        &::before {
          font-family: FontAwesome;
          // font-family: 'Proxima Nova Alt Rg';
          content: "\f078";
          font-size: 10px;
        }
      }
    }

    input[type="checkbox"] + label {
      top: 2px;
    }

    input[type="checkbox"]:checked + label {
      background: @color_primary;
      width: 20px;
      height: 20px;
      padding-left: 0;
      margin-right: 13px;
      border-radius: 0.3em;
      margin-bottom: -2px;

      &::before {
        top: 0;
        left: 1px;
        width: 8px;
        height: 13px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        -webkit-transform: rotateZ(37deg);
        transform: rotateZ(37deg);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
      }
    }
  }


  .mdb-select {
    border-style: solid;
    background-color: @color_primary_background !important;
    border-color: @color_primary_800;
    border-width: 1px;

    border-radius: 1em;
    padding: 0 20px;
    cursor: pointer;

  }
}

.login {
  h3 {
    margin-bottom: 1.8rem;
  }

  .list-group {
    margin-bottom: 35px;
  }

  .user-login {
    .btn {
      border-radius: 1.5em !important;
      background: @color_primary;
      min-width: 14em;
    }
  }

  .remember {
    padding-left: 15px;
  }

  .modal-footer {
    a {
      color: @color_primary;
      text-decoration: underline;
    }
  }
}

.password-reset {
  p {
    a {
      color: @color_primary;
      text-decoration: underline;
    }
  }
}

.tooltip {
  z-index: 999999;
}

.tooltip-inner {
  border-radius: 25px;
  background-color: @color_primary;
  //font-family     : 'Proxima Nova Alt Bold';
  font-family: "Proxima Nova Alt Rg";

  &::before {
    border-top-color: @color_primary !important;
  }
}

.profile_tab {
  margin-top: 0.5rem;

  .h3-responsive {
    text-decoration: none;

    &.underline {
      text-decoration: underline;
    }
  }

  form {
    label {
      left: auto;
      right: 20px;

      &.oakLabel {
        left: 0;
      }
    }

    input {
      min-width: 100%;
      max-width: auto;
      background: #f2f2f2 !important;
    }

    .select-wrapper {
      span.caret {
        right: 25px;
      }
    }

    input[type="checkbox"] + label {
      margin-left: 25px !important;
    }

    input[type="checkbox"]:checked + label {
      margin-right: 0px !important;
    }
  }
}

a.goto.load-more_btn {
  margin-left: auto;
  margin-right: auto;
  background: @color_primary;
  border: none;
  border-radius: 2em;
  width: 30%;
  padding: 0.3em;
  color: white;
  display: block;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
}

.csTabs {
  .tab-content {
    box-shadow: none;
  }

  ul.nav-tabs {
    background-color: @white !important;
    border-radius: 10px 10px 0 0;

    a {
      color: #000;

      &.active,
      &:hover {
        color: #000 !important;
        border-bottom: 2px solid @color_primary !important;
      }
    }
  }
}

.csArticle-page_wrapper {
  b.bq-title {
    font-weight: 900 !important;
    //font-family: 'Proxima Nova Alt Bold';
    font-family: "Proxima Nova Alt Rg";
  }

  h3 {
    font-size: 35px !important;
    margin-left: 0 !important;
    font-weight: 1000 !important;
    margin-bottom: 1.5ex;
    margin-top: 1.5ex;
    padding-left: 0;
    text-decoration: none !important;
    color: #310f36;
  }

  .loginArticle {
    .paywall-teaser {
      position: relative;
    }

    .paywall {
      background-color: white;
      padding: 5ex 4em;
      margin-top: 6ex;

      .link-style-1 {
        border-radius: 2em !important;
        padding: 0.6em 3em;
        box-shadow: none;
        background-color: @color_yellow !important;
        // font-size       : 0.7rem;
        min-width: 12em;
        margin: 0;
        color: @black-text-color !important;

        text-decoration: none;

        &:hover {
          background-color: @color_yellow_700 !important;
        }

        &:active {
          background-color: @color_yellow_800 !important;
        }

        &:disabled {
          background-color: @grey !important;
        }
      }
    }
  }

  img {
    &.img-fluid {
      border: 0.5em white solid;
    }
  }
  a {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  a.literatur {
    text-decoration: none;
  }
}

.main-content-widget {
  .article-list-wrapper {
    .paywall {
      background-color: white;
      padding: 5ex 4em;
      margin-top: 6ex;

      .link-style-1 {
        border-radius: 2em !important;
      padding: 0.6em 3em;
      box-shadow: none;
      background-color: #fce18a !important;
      min-width: 12em;
      margin: 0;
      color: #000 !important;
      text-decoration: none;
      }
    }
  }
}

.newsletters_page {
  h3 {
    justify-content: center;
    display: flex;
    text-decoration: none;
    color: @color_primary !important;
    font-size: 24px;
    margin-bottom: 15px;
  }
}

.bookmarks {
  article {
    .rating {
      display: inline-block;
      font-size: 0.8rem;
    }
  }
}

.article_title {
  color: @color_primary;

  h1 {
    font-size: 42px;
  }
}

#validationData {
  display: none;
}

.webui-popover-password-popover {
  @yellow: #f9d04e; // yellow
  @red: #f03d58; // red
  @green: #3ebe22; // green
  @orange: orange;
  @dark-green: #26724c;

  margin-left: 15px !important;
  font-size: 12px;

  .fa-times {
    color: @red;
  }

  .fa-check {
    color: @green;
  }

  .quality {
    border-bottom: 4px solid;
  }

  .quality0 {
    border-bottom-color: @red;
  }

  .quality1 {
    border-bottom-color: @red;
  }

  .quality2 {
    border-bottom-color: @yellow;
  }

  .quality3 {
    border-bottom-color: @yellow;
  }

  .quality4 {
    border-bottom-color: @green;
  }
}

select.ui-datepicker-year {
  display: inline !important;
}

.newsletter-feed {
  .send_message {
    margin-top: 20px;

    .error {
      color: #f03d58;
    }
  }

  .content {
    text-align: center;
    padding: 20px;

    a,
    button {
      font-weight: 900;
      top: 2px;
      margin-right: 10px;
    }
  }

  .select-wrapper {
    margin-right: 12px;
    display: inline;
  }

  input {
    background: #fff !important;
    max-width: 20em;
    border-radius: 6em;
    padding-left: 20px !important;
    padding-right: 20px !important;
    height: 2rem;

    &.week {
      border: none;
      color: #757575;
      margin-right: 20px;
      border-bottom: 1px solid #ccc;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #757575;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #757575;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #757575;
      }
    }

    &.select-dropdown {
      color: #757575;
      height: 2rem;
    }
  }

  span.caret {
    right: 16px;
    font-size: 0;
    top: 3px;
    padding: 0rem 0.7rem 0.2rem 0.7rem;
    z-index: 10;

    &::before {
      font-family: FontAwesome;
      // font-family: 'Proxima Nova Alt Rg';
      content: "\f078";
      color: #757575;
      font-size: 10px;
    }
  }
}

.fachthemen-select {
  &.kursart {
    .dropdown-menu {
      span {
        padding: 0.1rem 0.7rem;
      }
    }
  }
}

.topic-event-article,
.videoWidget {
  // margin-top: 25ex;

  .introtext {
    // margin-left: -30px;
  }

  .toplogo {
    // right: -4em;

    div {
      width: 100%;
    }
  }

  .logos,
  .group {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5ex;

    .article {
      margin-top: 2ex;
    }
  }

  .articleRating {
    text-align: end;
  }

  .logos {
    background-color: white;
    margin-right: -10px;
    margin-left: -10px;
    border-radius: 1em;

    .logo {
      height: auto;
      width: auto;
      max-height: 11ex;
      max-width: 12em;
      padding-left: 1em;
      padding-right: 1em;
      padding-top: 2ex;
      padding-bottom: 2ex;
    }
  }

  .buttons {
    margin-right: 0;
    margin-left: 0;

    a {
      border-radius: 2em !important;
      padding: 0.6em 1em;
      box-shadow: none;
      background-color: #f10083 !important;
      font-size: 0.7rem;
      min-width: 12em;
      margin: 0;
      color: white;
      text-transform: uppercase;
      //font-family     : 'Proxima Nova Alt Bold';
      font-family: "Proxima Nova Alt Rg";
      font-style: normal;
      font-weight: bold;
      line-height: 1.25;
      text-align: center;
      display: inline-block;
    }
  }

  .articleTeaser {
    margin-top: 2ex;
    margin-bottom: 2ex;
  }

  .image-carousel {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .video-carousel,
  .video {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    iframe {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }

    .embebThumbnails {
      //position: absolute;
      //bottom  : 0;
      text-align: center;

      .embebThumbnail {
        width: 5em;
        margin-left: 0.5em;
        margin-right: 0.5em;
        cursor: pointer;
      }
    }

    .carousel {
      .carousel-item {
        .item {
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .carousel {
    .carousel-indicators {
      bottom: 0;
      // left         : 25%;
      margin-bottom: 2ex !important;

      li {
        border-color: #f10083;

        &.active {
          background-color: #f10083;
        }
      }
    }

    .carousel-caption {
      bottom: 15%;
    }

    &.fachthemen-slider {
      .carousel-caption {
        bottom: 10%;

        h4 {
          font-size: 3rem;
        }
      }
    }

    .carousel-control-next,
    .carousel-control-prev {
      position: absolute;
      top: 0;
      bottom: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 15%;
      color: #fff;
      text-align: center;
      opacity: 0.5;
      color: #f10083;
      margin: auto 0;
      max-height: 100px;

      &:focus,
      &:hover {
        // color          : #fff;
        text-decoration: none;
        outline: 0;
        opacity: 0.9;
      }

      i {
        font-size: 5rem;
      }
    }

    .carousel-control-prev {
      left: 0;
    }

    .carousel-control-next {
      right: 0;
    }

    .slider-articles-teaser {
      .active {
        .teaserTitle {
          color: #f10083;
        }
      }

      .teaserStrapLine {
        // height     : 5ex;
        line-height: 0.9rem;
      }

      .teaserTitle {
        // height: 8ex;
      }
    }
  }
}

.carousel-thumbnails {
  .carousel-indicators {
    bottom: 10px;
  }
}


a.newsroom-link-style {
  border-radius: 2em !important;
  padding: 0.6em 1em;
  box-shadow: none;
  background-color: #fce18a !important;
  min-width: 12em;
  margin: 0;
  color: #000 !important;
  margin-right: 20px;
  text-decoration: none;

      &:focus,
      &:hover{
        background-color: #fcdd82 !important;
      }
}

a.link-style-linebreak {
  word-break: break-all;
}

.csArticle-page_section[data-name="uberUnsImage"] {
  .csMedia-left {
    width: 30%;
  }
}

.csMedia-row {
  float: left;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
}

/* IE11 */
.btn,
button {
  font-weight: normal !important;
}

.cookies-settings,
.cookies-accept {
  border: 2px solid rgb(118, 118, 118);
}

.cookies-settings {
  background: none;
}

.text-center {
  text-align: center !important;
}

.nejm_list {
  .select-wrapper {
    border-style: solid;
    background-color: #f5ecf5 !important;
    border-color: #652d65;
    border-width: 1px;
    border-radius: 2em;

    &.nejm-datefilterFrom-select {
      margin-left: -1em;
    }

  }
}

.nejm-article {

  .paywall-teaser {
    min-height: 30ex;
  }

  .paywall {
    margin: 0 !important;
    padding-top: 0 !important;
  }

  .name,
  h3 {
    font-size: 2rem;
    font-weight: 900;
    -webkit-font-smoothing: auto;
  }

  .italic {
    font-style: italic;
    margin-right: 4px;
  }

  .bold {
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
  }

  + .articleMediaButtons {
    text-align: left;
    margin-top: 10px;
  }

  .authorContent {
    h4 {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 5px 0;
    }
  }

  .section {
    h4 {
      border-top: 1px solid #ccc;
      padding-top: 10px;
    }
  }
}

a.more[href="#!"] {
  display: none;
}

@media screen and (min-width: 940px) {
  img.christmas-kontakt {
    width: 28% !important;
    margin-left: 42%;
    margin-top: -26% !important;
  }
}

@media screen and (min-width: 725px) {
  img.christmas-kontakt {
    width: 43%;
    /* margin-left: 42%; */
    /* margin-top: -26%; */
  }
}

@media screen and (max-width: 725px) {
  img.christmas-kontakt {
    width: 50%;
    /* margin-left: 42%; */
    /* margin-top: -26%; */
  }
}

.navigation-wrapper {
  margin-right: -4em;
  margin-left: -4em;
}

.error [type="checkbox"] + label:after {
  border: 2px solid red !important;
}

#newsletter-pop-up {
  .modal-dialog {
    top: 50%;
    margin: auto;
    transform: translateY(-50%);
  }

  .modal-content {
    padding: 2em;

    .modal-header {
      border: none;
      position: absolute;
      right: 0;
      top: 0;

      .text {
        font-size: 0.8rem;
        vertical-align: middle;
        color: black;

        text-transform: uppercase;
      }

      .close {
        vertical-align: middle;
        color: black;
        margin: 0;
        padding: 0;
        margin-left: 0.2em;
        opacity: 1;
        line-height: 0.3;
      }
    }

    .modal-body {
      word-break: break-word;
    }
  }
}

.news-list {
  // padding-left: 15px;
  // padding-right: 15px;
}

.registration {
  .caret {
    right: 25px !important
  }
}


// Special pages
body[class*="article-136579"],
body[class*="article-82839"] {
  .sidebar {
    margin-top: 10ex;
  }
}

