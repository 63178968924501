@flex-properties: flex-direction row,
    flex-direction column,

    flex ~'1 1 auto',

    align-items stretch,
    align-items flex-start,
    align-items flex-end,
    align-items center,

    align-self auto,
    align-self flex-start,
    align-self flex-end,
    align-self center,
    align-self baseline,
    align-self stretch,

    justify-content space-evenly,
    justify-content flex-start,
    justify-content flex-end,
    justify-content center,
    justify-content space-around,
    justify-content space-between,
    
    flex-wrap wrap,
    flex-wrap nowrap,
    flex-wrap wrap-reverse;

.make-classes(@prefix, @list) {
    .iter(length(@list));

    .iter(@i) when (@i > 0) {
        .iter(@i - 1);
        @pair         : extract(@list, @i);
        @key          : extract(@pair, 1);
        @value        : extract(@pair, 2);
        @name         : replace(@value, '\s+', '-', 'g');

        .@{prefix}-@{key}-@{name} {
            @{key}: @value;
        }
    }
}

.make-iterative-classes(@prefix, @start, @max, @step, @property, @type, @value-prefix) {
    .iter(@max);
     .iter(@i) when (@i > 0) {
         .iter(@i - 1);
         @value        : @start + @i * @step;
 
         .@{prefix}-@{i} {
             @{property}: @value-prefix ~'@{value}@{type}';
         }
     }
 }

.make-classes(fx, @flex-properties);
.make-iterative-classes(fx-gap, 0, 20, 0.5, gap, ~'%', ~'');
.make-iterative-classes(fx-gap-ex, 0, 10, 1, gap, ~'ex', ~'');
.make-iterative-classes(fx-flex, 0, 100, 1, flex, ~'%', ~'0 0');


.fx-flex {
    display: flex;
}

.fx-flex-1-0-auto {
    flex: 1 0 auto;
}