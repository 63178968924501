@media print {

    body {
        background-color: white;

        .navbar-fixed-top {
            .container {
                width: 100%;
            }
        }

        .tableContent {
            display: none;
        }

        .userBanner,
        .back-to-top,
        .imageGradient,
        .search-wrapper,
        .articleMediaButtons {
            display: none;
        }

        .info-wrapper {
            position: relative !important;
            bottom: 0 !important;

            .container {
                width: 90% !important;
            }
        }

        .collapse {
            display: block;
        }

        .selfCheck {

            .title {
                * {
                    color: white !important;

                    .fas,
                    .far {
                        &:before {
                            color: white !important;

                        }
                    }
                }
            }

            .selfCheckAnswer {
                .btn {
                    display: block !important;
                }
            }
        }

         

    }

}