@color_primary   : #f10083;
@background_light: #F2F2F2;
@background_dark : #4E4E4E;
@black           : #4E4E4E;
@white           : white;
@gray            : #aaa;


.registration.csWidget-wrapper {
    .h1-responsive {
        color: @color_primary;
    }

    .h3-responsive {
        text-decoration: none;
    }
}

.registration,
.confirmation,
.login,
.profile_tab,
.password-reset {
    form {

        .info-label {
            color: #757575;
        }

        .other_lands_arzt,
        .other_lands_andere {
            color        : #757575;
            margin-bottom: 12px;
        }

        label.verwendung {
            top: -3px !important;
        }

        .emailLabel {
            width: 12%;
        }

        .emailInput {
            width: 88%;

            input {
                max-width: none;
            }
        }

        input {
            background   : #fff !important;
            max-width    : 20em;
            border-radius: 6em;
            padding-left : 20px !important;
            padding-right: 20px !important;
            height       : 2rem;
            ;
        }

        label {
            font-size: 0.9rem;
            top: 0.25rem;
            &.twoLinesLabel {
                top: 0.3rem;
            }
        }

        input+label {
            padding-left: 20px;

            &.active {
                -webkit-transform: translateY(-175%);
                -moz-transform   : translateY(-175%);
                -ms-transform    : translateY(-175%);
                -o-transform     : translateY(-175%);
                transform        : translateY(-175%);
            }
        }

        input:focus:not([readonly]) {
            border-bottom     : 1px solid @color_primary  !important;
            -webkit-box-shadow: 0 1px 0 0 @color_primary  !important;
            -moz-box-shadow   : 0 1px 0 0 @color_primary  !important;
            box-shadow        : 0 1px 0 0 @color_primary  !important;
            background        : #fff !important;

        }

        input:focus:not([readonly])+label {
            color: @color_primary  !important;
        }

        .select-wrapper {
            input.select-dropdown {
                color : #757575;
                height: 2rem;
            }

            input.select-dropdown:focus {
                border-bottom     : 1px solid @color_primary  !important;
                -webkit-box-shadow: 0 1px 0 0 @color_primary  !important;
                -moz-box-shadow   : 0 1px 0 0 @color_primary  !important;
                box-shadow        : 0 1px 0 0 @color_primary  !important;
            }

            span.caret {
                right    : 16px;
                font-size: 0;
                top      : 8px;
                right    : 1px;
                z-index  : 1;
                cursor   : pointer;

                &::before {
                    font-family: FontAwesome;
                    // font-family: 'Proxima Nova Alt Rg';
                    content    : "\f078";
                    font-size  : 10px;
                }
            }
        }

        .select-wrapper+label {
            padding-left: 20px;

            &.active {
                -webkit-transform: translateY(-175%);
                -moz-transform   : translateY(-175%);
                -ms-transform    : translateY(-175%);
                -o-transform     : translateY(-175%);
                transform        : translateY(-175%);
            }
        }

        label.focused {
            color: @color_primary  !important;
        }

        .section {
            margin: 1rem 0;
        }

        input[type=checkbox]+label {
            top         : 2px;
            padding-left: 30px;
        }

        input[type=checkbox].filled-in:not(:checked)+label:after {
            border-radius: 0.4em;
            border-width : 1px;
        }

        input[type=checkbox].filled-in:checked+label:after {
            background-color: #f10083;
            border-color    : #f10083;
        }

        .fachgebiet li.optgroup {
            width: 100%;
            margin-top: 10px;

            &~li:not(.optgroup) {
                padding-left: 0;
            }
        }

        .input[type=checkbox]:checked+label input[type=checkbox]+label {
            top: 2px;
        }

        span {
            font-size: 0.8rem;
            padding  : 0rem 0.7rem 0.2rem 0.7rem;

            input[type=checkbox]:checked+label {

                background   : @color_primary;
                width        : 15px;
                height       : 15px;
                padding-left : 0;
                margin-right : 13px;
                border-radius: 0.3em;
                margin-bottom: -2px;
                margin       : 2px 13px 0 0;

                &::before {
                    top                     : -1px;
                    left                    : 1px;
                    width                   : 6px;
                    height                  : 11px;
                    border-top              : 2px solid transparent;
                    border-left             : 2px solid transparent;
                    border-right            : 2px solid #fff;
                    border-bottom           : 2px solid #fff;
                    -webkit-transform       : rotateZ(37deg);
                    transform               : rotateZ(37deg);
                    -webkit-transform-origin: 100% 100%;
                    transform-origin        : 100% 100%;
                }

            }

            input[type=checkbox]:not(:checked)+label:before {
                border-radius: 0.4em;
                border-width : 1px;
                width        : 15px;
                height       : 15px;
                top          : 2px;
            }

            input[type=checkbox]+label {
                padding-left: 22px;
            }
        }

        fieldset {
            label {
                font-size: 1rem;
            }
        }
    }

    .fachgebiet {
        ul {
            min-width : unset;
            max-width : unset;
            width     : 825px !important;
            min-height: unset;
            max-height: unset;
            height    : 250px;
            left      : -150px;

            li {
                float: left;
                width: 200px;
                height: 25px;

                span {
                    white-space  : nowrap;
                    overflow     : hidden;
                    text-overflow: ellipsis;
                }

                &.disabled{
                    display: inherit;
                    float: left;
                    width: 800px;
                    height: 25px;
                    border-top: 1px solid #eee;
                    margin-top: 10px;
                    margin-bottom: 2px;
                    span {
                        color: rgba(0, 0, 0, 0.4);
                        label{
                            padding-left: 0;
                            &::before{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .fachgebietSelected {
        .fachgebiet_selected {
            ul {
                li {
                    list-style-type: disc;
                    margin-left    : 30px;
                }
            }
        }
    }

    .fachgebietWrapper {
        .custom_container {
            text-overflow   : ellipsis;
            display         : inline-block;
            width           : 100%;
            overflow        : hidden;
            white-space     : nowrap;
            border-bottom   : 1px solid #ccc;
            box-sizing      : border-box;
            border-radius   : 6em;
            background-color: #ffffff;
            padding         : 0 20px 4px;
            cursor          : pointer;

            span.custom_label {
                margin-right: 10px;
                padding-top : 8px;
                float       : left;

                &.more {
                    top     : 3px;
                    position: absolute;
                }
            }

        }
    }
}

.profile_tab{
    form{
        input {
            background   : #f2f2f2 !important;
        }
    }
}

.passwordValidation{
    padding: 0 30px;
    li{
        color: red;
        font-size: .9rem;
        margin-bottom: 0;
    }

}

.captcha {
    background-color: #F2F2F2;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;

    .col-form-label {
        padding-top: 0rem;
    }

    .formula {
        display: flex;
        flex-direction: row;
        padding-top: 5px;
    }

    .captchaInput {
        background-color:lightyellow !important;
        text-align: center;
        height: 26px;
        width: 60px;
    }

    .captchaField {
        text-align: center;
        border-radius: 6em;
    }

    #showCaptcha {
        display: none;
    }
}

#showCaptcha {
    display: none;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    .captchaTitle{
        padding: 10px;
        padding-left: 10px;
        padding-bottom: 5px;
    }

    .section{
        margin-bottom: 0px;
        font-size: 20px !important;
    }

    .formContent{
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 0px;
        padding-top: 10px;
    }

    .label{
        width: auto;
        padding-left: 0px;
    }

    .col-form-label{
        padding: 0px;
        color: #757575;
        font-size: 0.9rem;
    }

    .formula{
        padding: 0px;
        width: auto;
        padding-left: 10px;
        .col-lg-2{
            width: auto;
        }
    }

    span {
        font-size: 0.8rem;
        padding: 0rem 0.7rem 0.2rem 0.7rem;
    }

    input{
        font-size: 1rem;
        padding-left: 20px !important;
        padding-right: 20px !important;
        padding-bottom: 0.6rem;
        padding-top: 0.5rem;
        border-radius: 6em;
    }

}

#showCaptchaSide {
    display: none;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 0px;
    padding-right: 10px;
    padding-left: 10px;

    .captchaTitle{
        padding: 10px;
    }

    .section{
        margin-bottom: 0px;
        color: #310f36 !important;
    }

    .formContent{
        display: flex;
        flex-direction: column;
        padding-right: 0px;
    }

    .label{
        width: 100%;
        padding: 0px;
        padding-bottom: 0.3rem;
    }

    .col-form-label{
        padding: 0px;
        color: #757575;
        text-align: center;
        font-size: 0.6rem;
    }

    .formula{
        width: 100%;
        padding: 0px;
        padding-right: 10px;
        justify-content: center;
        .col-lg-2{
            min-width: 15%;
            width: auto;
        }
    }

    .form-control{
        border-radius: 6em;
        font-size: 0.8rem;
        padding: 0rem 0.7rem 0.2rem 0.7rem;
    }
}

.eintragungsWrapper, .apothekeWrapper {
    margin-bottom: 1.5rem;
}


@media screen and (max-width: 1024px) {
    #showCaptcha {
        float: left;
        width: 100%;
        margin-top: 20px;
        border-radius: 5px;

        .captchaTitle{
            text-align: start;
            padding-bottom: 15px;
            padding-top: 15px;
        }

        .formContent{
            display: flex;
            flex-direction: row;
            padding-top: 0px;
        }

        .formula{
            width: 50%;
        }
        .col-lg-2{
            width: 16.66667% !important;
        }
    }
}

@media screen and (max-width: 768px) {
    #showCaptcha {

        .formContent{
            flex-direction: column;
        }

        .label{
            width: 100%;
            text-align: start;
        }

        .formula{
            width: 60%;
        }

        .col-lg-2{
            width: auto !important;
        }
    }
}

@media screen and (max-width: 480px) {
    #showCaptcha {
        .formContent{
            flex-direction: column;
            gap: 0px;
        }

        .label{
            width: 100%;
            padding-bottom: 10px;
        }

        .formula{
            width: 100%;

            .col-lg-2{
                min-width: 10% !important;
            }
        }
    }
}