.nejm_list {
    .long_breadcrumb {
        margin: 0;
    }
    .articles {
        .article {

            .name {
                font-size: 1.5rem;
                color    : black;
            }

            .authors {
                color     : #808080;
                font-size : 0.9rem;
                font-style: italic;

                span {
                    &:after {
                        content: ' ';
                    }
                }
            }

            .blurb {
                color    : black;
                font-size: 0.9rem;
            }

        }

        .clearfix {
            margin-bottom: 3ex;
        }
    }

    .nejm-searchTerm-value {
        background-color: white;
        width           : 100%;
        border-radius   : 1em;
        padding-left    : 20px;
        padding-right   : 20px;
        height          : 5ex;
        border          : none;
        margin-bottom   : 2ex;
    }

    @media screen and (max-width: 767px) {
        .dateFilterTo {
            margin-left: 0em !important;
	        margin-top: -1ex !important;
            width: 100%;
            text-align: center;

        }
        .select-wrapper{
            width: 90%;
        }
        .show-mobile {
            margin-top: 160px !important;
        }
        .nejm-datefilterTo-select{
            margin-top: 20px;
        }
        
    }
    

    .dateFilterTo {
        float      : left;
        margin-left: -2em;
        margin-top : 1ex;
    }

    .select-wrapper {
        background-color: white;
        border-radius   : 1em;
        padding         : 0 20px;
        width           : 85%;
        float           : left;
        margin-right    : 0;
        margin-left     : 1em;
        cursor          : pointer;

        ul {
            max-width: 100%;
        }

        &.long {
            max-width: 60%;
            min-width: 50%;
        }

        &.selected {
            border    : 1px @color_primary solid;
            min-height: 40px;

            .custom_container {
                float: left;
                width: 110%;

                span.custom_label {
                    margin-right: 10px;
                    padding-top : 8px;
                    float       : left;

                    &.more {
                        top     : 3px;
                        position: absolute;
                    }
                }

            }
        }

        input[type=text] {
            margin-bottom: 0;
            border-bottom: none;
            max-height   : 40px;
            width        : 120%;
            left         : -1.3em;
            padding-left : 1.3em;
        }

        span.caret {
            right    : 16px;
            font-size: 0;
            top      : 14px;

            &::before {
                font-family   : FontAwesome;
                // font-family: 'Proxima Nova Alt Rg';
                content       : "\f078";
                font-size     : 10px;
            }
        }
    }
}