.ad_hidden {
  display: none;
}

.banner.artikel_superbanner_3,
.banner.artikel_superbanner_4,
.banner.artikel_superbanner_5,
.banner.artikel_superbanner_6,
.row.big_banner + .row.small_banner {
  margin-top: -34px !important;

  @media screen and (max-width: 1024px) {
    margin-top: -32px !important;
  }

  @media screen and (max-width: 767px) {
    // margin-top: -18px !important;
    margin-top: 0 !important;
    margin-left: -15px;
  }
}

.banner.artikel_superbanner_1,
.banner.artikel_superbanner_2 {
  @media screen and (max-width: 767px) {
    & {
      margin-bottom: 0px !important;
    }
  }
}

.banner.artikel_superbanner_3,
.banner.artikel_superbanner_4,
.banner.artikel_superbanner_5,
.banner.artikel_superbanner_6 {
  @media screen and (max-width: 690px) {
    margin-top: -12px !important;
  }

  @media screen and (max-width: 620px) {
    margin-top: -18px !important;
  }
}

.row.big_banner {
  @media screen and (max-width: 767px) {
    .event_superbanner_1,
    .event_superbanner_2,
    .event_superbanner_3,
    .event_superbanner_4,
    .event_superbanner_5 {
      margin-bottom: 0px !important;
    }
  }

  & + .small_banner.fki {
    @media screen and (max-width: 690px) {
      margin-top: -12px !important;
    }

    @media screen and (max-width: 620px) {
      margin-top: -18px !important;
    }
  }
}

.banner_fachthement_page {
  padding-left: 0;
  text-align: center;
}


/* Parallax Banner START */
.parallax__banner {
  -webkit-transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
}

.parallax__banner {
  position: relative;
  height: 500px;
  /* fallback for older browsers */

  height: 100vh;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.parallax__block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin-x: 100%;
}

.parallax__block--base {
  width: 34%;
  padding-top: 34%;
  -webkit-transform: translate3d(-50%, -50%, -300px) scale(2);
  transform: translate3d(-50%, -50%, -300px) scale(2);
  z-index: -2;
}

#group3 .parallax__block--base {
  left: 55%;
  background: rgb(153, 216, 101);
}
/* Parallax Banner END */


.banner {
  position: relative;
  display: none;

  .bannerContainer {
    display: inline-block;
    text-align: left;
    text-decoration: none;

    iframe.iframeAds {
      width: 100%;
      height: 100%;
    }
  }

  &.artikel_media_rectangle {
    text-align: center;

    .bannerContainer {
      width: 300px;
      height: 250px;
      margin-bottom: 2rem;
    }
  }

  &.superBanner {
    text-align: center;
    margin-top: 10ex;

    .bannerContainer {
      width: 728px;
      height: 90px;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;

      .bannerContainer {
        max-height: 90px;
        width: 90%;
      }
    }
  }

  &.nejm_superbanner_1,
  &.nejm_superbanner_2,
  &.nejm_superbanner_3,
  &.nejm_superbanner_4 {
    text-align: center;
    margin-bottom: 25px;

    .bannerContainer {
      width: 728px;
      height: 90px;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;

      .bannerContainer {
        max-height: 90px;
        width: 90%;
      }
    }
  }

  &.nejm_media_rectangle_1,
  &.nejm_media_rectangle_2 {
    position: absolute;
    right: 0;
    top: 0;

    .bannerContainer {
      width: 300px;
      height: 250px;
      margin-bottom: 2rem;
    }

    @media screen and (max-width: 1024px) {
      position: initial !important;
      text-align: center;
    }
  }

  &.artikel_superbanner_1,
  &.artikel_superbanner_2,
  &.event_superbanner_1,
  &.event_superbanner_2,
  &.event_superbanner_3,
  &.event_superbanner_4,
  &.event_superbanner_5 {
    text-align: center;
    width: 100%;
    margin-bottom: 2rem;

    .bannerContainer {
      width: 728px;
      height: 90px;
      transform: translateX(-42px);

      iframe.iframeAds {
        max-height: 90px;
      }
    }

    @media screen and (max-width: 1024px) {
      width: 100%;

      .bannerContainer {
        width: 100%;
        max-height: 90px;
        transform: none;
      }
    }

    @media screen and (max-width: 480px) {
      .bannerContainer {
        height: 75px;
      }
    }

    @media screen and (max-width: 380px) {
      .bannerContainer {
        height: 60px;
      }
    }
  }

  &.fachthemen_superbanner_5,
  &.fachthemen_superbanner_6,
  &.event_superbanner_6,
  &.event_superbanner_7 {
    text-align: center;
    margin-bottom: 15px;
    width: 100%;
    margin-top: 15px;

    .bannerContainer {
      height: 20px;
      width: 100px;
      margin-left: -631px;
    }

    @media screen and (max-width: 1024px) {
      text-align: left;
      width: 95%;

      .bannerContainer {
        margin-left: inherit;
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }

  &.fachthemen_superbanner_7,
  &.fachthemen_superbanner_8,
  &.event_superbanner_8,
  &.event_superbanner_9 {
    text-align: center;
    margin-bottom: 15px;
    width: 100%;
    margin-top: 15px;

    .bannerContainer {
      height: 20px;
      width: 500px;
      margin-left: 0px;
    }

    @media screen and (max-width: 1024px) {
      text-align: left;
      width: 95%;

      .bannerContainer {
        margin-left: inherit;
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }

  &.artikel_superbanner_3,
  &.artikel_superbanner_4 {
    text-align: center;
    margin-bottom: 15px;
    width: 100%;
    margin-top: 15px;

    .bannerContainer {
      height: 20px;
      width: 100px;
      margin-left: -631px;
    }

    @media screen and (max-width: 1024px) {
      text-align: left;
      width: 95%;

      .bannerContainer {
        margin-left: inherit;
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }

  &.artikel_superbanner_5,
  &.artikel_superbanner_6 {
    // text-align      : center;
    margin-bottom: 15px;
    width: 100%;
    margin-top: -43px !important;
    // margin-left  : 115px;
    text-align: right;
    margin-left: 44px;

    .bannerContainer {
      height: 20px;
      width: 500px;
      margin-left: 0px;
    }

    @media screen and (max-width: 1024px) {
      // text-align     : left;
      width: 95%;
      // 3margin-left: 106px;

      .bannerContainer {
        margin-left: inherit;
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
      margin-left: -15px;
      margin-top: -18px !important;
    }
  }

  // &.event_superbanner_1,
  // &.event_superbanner_2,
  // &.event_superbanner_3,
  // &.event_superbanner_4,
  // &.event_superbanner_5 {
  //     @media screen and (max-width: 1260px) {
  //         .bannerContainer {
  //             width: 100%;
  //             iframe{
  //                 max-height: inherit !important;
  //                 height: auto;
  //                 min-height: 120px;
  //             }
  //         }
  //     }
  // }

  &.event_superbanner_1 {
    margin-top: 25px;
    margin-bottom: -20px;
  }

  &.event_superbanner_2 {
    margin-bottom: 25px;

    @media screen and (max-width: 1024px) {
      width: 95% !important;
      margin: auto;
      position: relative !important;
      display: block !important;
    }
  }

  &.event_superbanner_3 {
    margin-bottom: -30px;
    margin-top: 25px;
  }

  &.halfPage {
    .bannerContainer {
      width: 160px;
      height: 600px;
      right: 0;
      top: 8ex;
      padding-top: 1.85em;
      transform: translateX(20px);
      position: absolute;
    }

    &.long {
      .bannerContainer {
        height: 600px;
        width: 300px;
        transform: translateX(160px);
      }
    }
  }

  &.halfPage_response,
  &.artikel_halfpage_response,
  &.event_halfpage_response,
  &.fachthemen_halfpage_response,
  &.fachthemen_halfpage_fki_response,
  &.artikel_halfpage_fki_response {
    opacity: 0;
    height: 0;
  }

  &.fachthemen_halfpage_fki {
    margin-left: 15px;
    .bannerContainer {
      margin-left: -35px;
      width: 100px;
      height: 20px;

      @media screen and (max-width: 1260px) {
        margin-left: -25px;
      }

      @media screen and (max-width: 1024px) {
        margin-left: 0px;
      }
    }

    @media screen and (max-width: 1260px) {
      margin-left: 0px;
    }

    @media screen and (min-width: 1800px) {
      margin-left: 0px;
    }

    @media screen and (max-width: 1024px) {
      display: none !important;
    }
  }

  &.fachthemen_halfpage {
    top: 50ex;

    .bannerContainer {
      // width: 160px;
      // height:600px;
      // right: 0;
      // transform: translateX(180px);
      // position: absolute;
      width: 160px;
      height: 600px;
      margin-left: 0px;

      @media screen and (max-width: 1024px) {
        margin-left: 0px;
      }
    }

    &.long {
      // .bannerContainer{
      //     width: 300px;
      //     transform: translateX(300px);
      // }
      margin-left: 15px;

      .bannerContainer {
        width: 300px;
        margin-left: -35px;
      }

      @media screen and (max-width: 1260px) {
        margin-left: 0px;
      }

      @media screen and (min-width: 1800px) {
        margin-left: 0px;
      }
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &.artikel_halfpage_fki {
    .bannerContainer {
      width: 100px;
      height: 20px;
      margin-left: 40px;
      margin-top: -10px;
    }

    @media screen and (max-width: 1024px) {
      display: none !important;
    }
  }

  &.artikel_halfpage {
    .bannerContainer {
      width: 160px;
      height: 600px;
      margin-left: 40px;

      @media screen and (max-width: 1024px) {
        margin-left: 20px;
      }

      @media screen and (max-width: 900px) {
        margin-left: 0px;
      }

      @media screen and (max-width: 768px) {
        margin: 10px auto;
        display: block;
      }
    }

    &.long {
      .bannerContainer {
        width: 300px;
        margin-left: -25px;
      }

      @media screen and (max-width: 1260px) {
        .bannerContainer {
          width: 100%;

          iframe.iframeAds {
            width: 90%;
          }
        }
      }

      @media screen and (max-width: 767px) {
        .bannerContainer {
          width: 100%;
          text-align: center;

          iframe.iframeAds {
            max-width: 300px;
          }
        }
      }
    }
  }

  &.event_halfpage {
    .bannerContainer {
      width: 160px;
      height: 600px;
      margin-left: 0px;

      @media screen and (max-width: 1024px) {
        margin-left: 0px;
      }
    }

    &.gifBanner {
      margin-top: 4rem;
    }

    &.long {
      margin-left: 15px;

      .bannerContainer {
        width: 300px;
        margin-left: -35px;
      }

      @media screen and (max-width: 1260px) {
        margin-left: 0px;
      }
    }
  }

  &.slider_event_french,
  &.slider_start,
  &.slider_fachthemen {
    text-align: center;
    width: 100%;
    height: 100%;

    .bannerContainer {
      // width: 1240px;
      // height: 698px;
      width: 100%;
      height: 100%;
    }
  }

  &.event_superbanner_1 {
    margin-left: 40px;

    @media screen and (max-width: 1024px) {
      margin-left: 0px;
    }
  }

  &.event_banner {
    position: inherit;
    display: inline-block;

    &.event_media_rectangle_1,
    &.event_media_rectangle_2,
    &.event_media_rectangle_3,
    &.event_media_rectangle_4,
    &.event_media_rectangle_5,
    &.event_media_rectangle_6 {
      width: auto;
      height: 100%;

      iframe.iframeAds {
        height: 250px;
        width: 100%;
      }

      @media screen and (max-width: 1024px) {
        height: 100%;
        width: auto;
      }
    }

    &.event_superbanner_1,
    &.event_superbanner_2,
    &.event_superbanner_3,
    &.event_superbanner_4,
    &.event_superbanner_5 {
      text-align: center;
      margin-bottom: 15px;
      margin-top: 15px;
      width: 100%;

      iframe.iframeAds {
        height: 90px;
        width: 728px;
      }

      @media screen and (max-width: 1024px) {
        width: 90%;

        iframe.iframeAds {
          max-height: 90px;
          width: 100%;
        }
      }

      @media screen and (max-width: 767px) {
        width: 100%;

        iframe.iframeAds {
          width: 100% !important;
        }
      }

      @media screen and (max-width: 480px) {
        iframe.iframeAds {
          height: 60px;
        }
      }
    }

    &.event_superbanner_6,
    &.event_superbanner_7 {
      text-align: center;
      margin-bottom: 15px;
      width: 100%;

      iframe.iframeAds {
        height: 20px;
        width: 100px;
        margin-left: -628px;
      }

      @media screen and (max-width: 1024px) {
        margin-left: -193px;
        text-align: center;
        width: 95%;

        iframe.iframeAds {
          margin-left: inherit;
        }
      }

      @media screen and (max-width: 860px) {
        margin-left: -196px;
      }

      @media screen and (max-width: 767px) {
        text-align: left;
        margin-left: 1.25%;
      }
    }

    &.event_superbanner_8,
    &.event_superbanner_9 {
      text-align: center;
      margin-bottom: 15px;
      width: 100%;

      iframe.iframeAds {
        height: 20px;
        width: 500px;
        margin-left: 0;
      }

      @media screen and (max-width: 1024px) {
        text-align: center;
        width: 95%;
        margin-left: 93px;

        iframe.iframeAds {
          margin-left: inherit;
        }
      }

      @media screen and (max-width: 860px) {
        margin-left: 91px;
      }

      @media screen and (max-width: 767px) {
        text-align: left;
        margin-left: 1.25%;
      }
    }
  }

  &.fachthemen_banner {
    position: inherit;
    display: inline-block;

    &.start_page {
      // transform: translateX(-40px);
      iframe.iframeAds {
        // height: 250px;
        // width: 300px;
        min-height: 250px;
        width: 100%;
      }
    }

    &.fachthemen_superbanner_5,
    &.fachthemen_superbanner_6 {
      text-align: center;
      margin-bottom: 15px;
      width: 100%;

      iframe.iframeAds {
        height: 20px;
        width: 100px;
        margin-left: -628px;
      }

      @media screen and (max-width: 1024px) {
        text-align: left;
        width: 95%;
        margin-left: 35px;

        iframe.iframeAds {
          margin-left: inherit;
        }
      }

      @media screen and (max-width: 768px) {
        margin-left: 0;

        iframe.iframeAds {
          margin-left: inherit;
        }
      }
    }

    &.fachthemen_superbanner_7,
    &.fachthemen_superbanner_8 {
      text-align: center;
      margin-bottom: 15px;
      width: 100%;
      //margin-left: -202px;

      iframe.iframeAds {
        height: 20px;
        width: 500px;
        margin-left: 0;
      }

      @media screen and (max-width: 1024px) {
        text-align: right;
        width: 95%;
        margin-left: -104px;

        iframe.iframeAds {
          margin-left: inherit;
        }
      }

      @media screen and (max-width: 768px) {
        margin-left: 66px;

        iframe.iframeAds {
          margin-left: inherit;
        }
      }
    }

    &.fachthemen_media_rectangle_1,
    &.fachthemen_media_rectangle_2,
    &.fachthemen_media_rectangle_3,
    &.fachthemen_media_rectangle_4 {
      text-align: center;
      // right: 15px;
      width: 100%;
      height: auto;

      max-width: 300px;
      max-height: 250px;

      iframe.iframeAds {
        height: 250px;
        // min-height: 491px;
        width: 100%;

        @media screen and (max-width: 1480px) {
          width: 100% !important;
        }
      }

      @media screen and (max-width: 1024px) {
        height: 100%;
        width: auto;
      }
    }

    &.fachthemen_superbanner_1,
    &.fachthemen_superbanner_2,
    &.fachthemen_superbanner_3,
    &.fachthemen_superbanner_4 {
      text-align: center;
      margin-bottom: 15px;
      width: 100% !important;

      iframe.iframeAds {
        height: 90px;
        width: 728px;
      }

      @media screen and (max-width: 1024px) {
        width: 90% !important;

        iframe.iframeAds {
          max-height: 50px;
          width: 100% !important;
        }
      }

      @media screen and (max-width: 767px) {
        width: 100% !important;
      }
    }
  }

  &.article_medium_rectangle {
    position: inherit;
    display: inline-block;

    iframe {
      min-height: 250px;
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.event_media_rectangle_1,
  &.event_media_rectangle_2,
  &.event_media_rectangle_3,
  &.event_media_rectangle_4,
  &.event_media_rectangle_5,
  &.event_media_rectangle_6 {
    text-align: center;

    iframe.iframeAds {
      min-height: 250px;
      width: 100%;
    }
  }

  &.artikel_video_pop_up {
    pointer-events: all;
    z-index: 9999999999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .bannerContainer {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .banner_start_page,
  .banner_fachthement_page {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    .banner.halfPage,
    .banner.fachthemen_halfpage,
    .banner.artikel_halfpage,
    .banner.event_halfpage {
      display: none !important;
    }
  }

  &.fachthemen_halfpage_fki_response,
  &.artikel_halfpage_fki_response {
    display: inline-block !important;
    width: 100% !important;
    opacity: 100 !important;

    .bannerContainer {
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: -18px;
      margin-left: -100px;
      width: 100px;
      height: 20px;

      iframe.iframeAds {
        width: 100px;
        height: 20px;
      }
    }
  }

  &.artikel_halfpage_fki_response {
    .bannerContainer {
      margin-left: -30px;
      width: 100px;
      height: 20px;
    }
  }

  &.halfPage_response,
  &.fachthemen_halfpage_response,
  &.artikel_halfpage_response {
    display: inline-block !important;
    width: 100% !important;
    min-height: 600px;
    opacity: 100 !important;

    .bannerContainer {
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      iframe.iframeAds {
        width: 160px;
        min-height: 600px;
      }
    }

    &.long {
      .bannerContainer {
        iframe.iframeAds {
          width: 300px;
        }
      }
    }
  }

  &.event_halfpage_response {
    display: none;
    width: 100% !important;
    min-height: 600px;
    opacity: 100 !important;
    margin-top: 5ex;

    .bannerContainer {
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      iframe.iframeAds {
        width: 160px;
        min-height: 600px;
      }
    }

    &.long {
      .bannerContainer {
        iframe.iframeAds {
          width: 300px;
        }
      }
    }
  }
}

.fki + .ref {
  margin-top: -57px;
  display: block;
  margin-left: 212px;

  @media screen and (max-width: 1024px) {
    margin-top: -56px;
    margin-right: inherit;
    margin-left: inherit;
  }

  @media screen and (max-width: 767px) {
    margin-top: -18px;
    margin-right: auto;
    margin-left: -14px;
    float: none;
  }
}

/* Custom banner sizing */
.topic-event-article[data-topic-article="2191568"] {
  .banner[class*="event_media_rectangle"] {
    .iframeAds {
      min-height: 300px;
    }
  }
}

.banner_event_page {
  text-align: center;
}

.topic-event-article {
  .superbanner {
    width: 100%;
    max-width: unset !important;
    // height: 98px;
    // background-color: red;
  }
}

.article-content {
    .superbanner {

        @media screen and (max-width: 1024px) {
          max-width: 90vw !important;

          img {
            max-width: 90vw;
            height: auto !important;
            margin-left: 0;
            margin-left: 50%;
            transform: translateX(-50%);
          }
        }

    }
}

.article-wrapper {
  &.superbanner, &.superbanner-fki {
    flex: 0 0 100%;
    max-width: unset !important;

    @media screen and (max-width: 1024px) {
      max-width: 90vw !important;

      img {
        max-width: 90vw;
        height: auto !important;
        margin-left: 0;
        margin-left: 50%;
        transform: translateX(-50%);
      }
    }
    // height: 98px;
    // background-color: red;
  }
}

.billboard {
  //max-width: 90vw !important;
  ins {
    width: 90%;
    max-width: 1280px;

    .asmbannerimg {
      width: 100% !important;
      height: auto !important;
    }
  }

  @media screen and (max-width: 1024px) {
    //max-width: 90vw !important;

    img {
      //max-width: 90vw;
      height: auto !important;
      margin-left: 0;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}

.superbanner-fki {

    margin-top: -3ex;
    //margin-left: -40em;

  position: relative;

  ins {

    .adp-wrapper {
      position: absolute;
      top: -2ex;
    }
  }
}

//Responsive

// Google banners

.article-wrapper {
  &.ldb {
    flex: 0 0 100% !important;
    max-width: unset !important;

    @media screen and (max-width: 1024px) {
      flex: 0 0 auto !important;
    }
  }

  .configurable-widget {
    &.ldb {
      iframe {
       // width: 100%;
      }
    }
  }
}
