@color_primary : #f10083;

.article-intro {
    font-family   : 'Proxima Nova Alt Bold';
    // font-family: 'Proxima Nova Alt Rg';
    font-size     : 1.2rem;
}

.parseHtml,
.html-string {

    em,
    strong {
        font-weight   : 900 !important;
        font-family   : 'Proxima Nova Alt Bold';
        // font-family: 'Proxima Nova Alt Rg';
    }

    a {
        color: #0275d8;
    }

    ul {
        li {
            list-style-type: square;
            margin-left    : 2em;
        }
    }

    #keypoints {
        ul {
            li {
                margin-left: 0;
            }
        }
    }

    #rot {
        padding      : 1em 1.5em;
        border-top   : 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-bottom: 1em;
        text-align   : center;
        display      : table;


        font-size  : 1.2rem;
        font-weight: bold;
        font-style : italic;

    }

}

.article-content {

    .authorinfo {
        p {
            margin-bottom: 0;
        }

        a {
            word-break: break-all;
        }
    }

    .bit {
        margin-bottom: 1ex;
        background   : #f2f6f7;
        border-left  : 1em solid #94b7bc;
        padding      : 1ex;
    }

    .selfCheck {

        clear        : both;
        background   : #f2f6f7;
        margin-bottom: 2ex;

        .title {
            background: #94b7bc;
            color     : white;
            font-size : 1.2rem;
            padding   : 1ex 0.5em;

            .fa-stack {
                font-size: 0.7em;

                .fa-circle-o {
                    font-size: 1.7em;
                }

                .fa-check {
                    font-size  : 1.5em;
                    margin-left: 0.1em;
                    margin-top : -0.1ex;
                }
            }
        }


        .selfCheckQuestion {

            p {
                padding: 1ex 1em;
            }

            .selfCheckAnswer {

                p {
                    padding-top: 1ex;
                }

                .btn {
                    width        : 100%;
                    text-align   : left;
                    font-weight  : 900 !important;
                    //font-family: 'Proxima Nova Alt Bold';
                    font-family  : 'Proxima Nova Alt Rg';
                    background   : #94b7bc !important;
                    margin       : 0;
                    padding      : 1em;
                    color        : white !important;

                    &:hover {
                        background: #5a8186 !important;
                    }
                }

            }
        }
    }

    #fazit,
    .articleContentBox {
        background-color: white;
        padding         : 1.5em 1.5em .5em;
        border-radius   : 1em;
        margin-bottom   : 1.5em;

        h2,
        h5 {
            font-size: 18px;
            color    : @color_primary;
        }


        ul {
            list-style  : none;
            padding-left: 5em;

            li {
                display    : flex;
                align-items: baseline !important;

                &::before {
                    content       : "\2022";
                    color         : @color_primary;
                    font-weight   : bold;
                    display       : inline-block;
                    // width      : 1em;
                    font-size     : 25px;
                    margin-left   : -1em;
                }

                p {
                    margin-left  : 1em;
                    margin-bottom: 0 !important;
                }
            }
        }

        ol {
            padding-left: 3.5em;
            display     : table;
            margin-left : 0;
            list-style  : none;

            li {

                // color: @color_primary;
                list-style       : decimal;
                display          : table-row;
                counter-increment: table-ol;

                &:before {
                    content      : counter(table-ol) ".";
                    display      : table-cell;
                    padding-right: 0.4em;
                    text-align   : left;
                }

                p {
                    color        : #4E4E4E;
                    margin-left  : 0.3em;
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    .articleContentBoxes {
        max-width  : 260px;
        margin-top : 30px;
        float      : right;
        margin-left: 20px;

        ul {
            padding-left: 3em;
        }

        h3 {
            text-decoration: none;
        }
    }

    @media screen and (max-width:767px) {
        .articleContentBoxes {
            float       : none;
            max-width   : initial;
            margin-right: 20px;
        }

        .reading-time-article {
            margin-bottom: 3ex;
        }

        .date-article,
        .authors-article,
        .reading-time-article,
        .articleMediaButtons {
            padding-right: 3vw;
        }
    }


    .article-content-main {

        p {
            text-align: left;
        }

        .articleContentBoxes {
            &.left {
                display   : none;
                // display: inline;
                margin    : 0 2rem;

                @media screen and (max-width: 1340px) {
                    max-width: 210px;
                    margin   : 0;
                }

                @media screen and (max-width: 1024px) {
                    margin   : 2rem;
                    max-width: inherit;
                }

            }
        }

        .quotes {
            padding      : 1em 1.5em;
            border-top   : 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            margin-bottom: 1em;
            text-align   : center;
            display      : table;

            .content {
                font-size  : 12px;
                font-weight: bold;
                font-style : italic;

                span {
                    font-size: 20px;
                }
            }

            .info {
                padding-top: 1em;
                font-size  : 13px;
            }
        }

        #keypoints,
        .keypoints {

            padding      : 1em 1.5em 0em;
            border-top   : 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            margin-bottom: 1em;

            h2,
            .keypoints_title {
                color      : @color_primary;
                font-size  : 24px;
                font-weight: 900;
            }

            ul {
                list-style  : none;
                padding-left: 5em;
                margin-left : 0;

                li {
                    display    : flex;
                    align-items: baseline !important;

                    &::before {
                        content      : "\2022";
                        color        : @color_primary;
                        font-weight  : bold;
                        display      : inline-block;
                        width        : 1em;
                        font-size    : 25px;
                        margin-left  : -1em;
                        padding-right: 1em;
                    }

                    p {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

        #keypoints {
            ul {
                li {
                    display: block;
                }
            }
        }

        .empfehlungen {

            padding      : 1em 1.5em 1em;
            border-top   : 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            margin-bottom: 3em;
            margin-top: 3em;

            h2,
            .empfehlungen_title {
                color      : @color_primary;
                font-size  : 24px;
                font-weight: 900;
            }
            p {
                margin-bottom: 0;
                margin-top: 15px;
            }


            ul {
                list-style  : none;
                padding-left: 5em;
                margin-left : 0;

                li {
                    display    : flex;
                    align-items: baseline !important;

                    &::before {
                        content      : "\2022";
                        color        : @color_primary;
                        font-weight  : bold;
                        display      : inline-block;
                        width        : 1em;
                        font-size    : 25px;
                        margin-left  : -1em;
                        padding-right: 1em;
                    }

                    p {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

        .praxistipp {
            width        : 250px;
            border-left  : 1px solid @color_primary;
            padding-left : 20px;
            float        : right;
            margin-bottom: 5px;
            margin-left  : 15px;

            @media screen and (max-width: 1340px) {
                width: 210px;
            }

            @media screen and (max-width: 1024px) {
                width  : auto;
                margin : 0 2rem;
                display: inline-block;
                float  : inherit;
            }

            h3 {
                display    : inline;
                color      : @color_primary;
                font-weight: 900;
                font-size  : 20px;
            }

            .praxistipp_title {
                color        : @color_primary;
                margin-bottom: 10px;
                font-weight  : 900;
            }

            .icon {
                width        : 36px;
                background   : @color_primary;
                height       : 36px;
                float        : left;
                border-radius: 2em;
                margin-right : 15px;
                margin-bottom: 10px;

                i.fa {
                    color    : white !important;
                    font-size: 26px;
                    padding  : 5px 5px 5px 6px;
                }
            }
        }
    }
}

.literatur {
    .h4-responsive {
        width        : 100%;
        border-top   : solid 2px #dddddd;
        padding-top  : 1ex;
        display      : block;
        color        : #aaa;
        margin-top   : 10ex;
        margin-bottom: 2ex;
        font-size    : 0.9rem;

        &:before {
            content     : '>';
            margin-right: 1em;
            margin-left : 0.5em;
            display     : inline-block;
        }
    }
}

.tableContent {
    .title {
        width             : 95%;
        margin-right      : 0;
        margin-left       : auto!important;
        // background     : #f2f7fa;
        // border         : 1px solid #fbe7f0;
        border-bottom     : 1px solid #a1a6a9;
        padding           : 1ex;
        // text-decoration: underline;
        font-size         : 0.8rem!important;
        color             : #a1a6a9;
    }
}

#imgZoom {
    z-index: 99999;

    .close {
        position: absolute;
        top     : 1ex;
        right   : 1em;
    }
}

p {
    &.color-red {
        color: red;
    }
}
