.video-replace-wrapper {

    & > * {
        display: none;
    }

    // Add class in HTML Transformation
    div {
        display: block;
    }

}

.video-article-list-wrapper {
    .videoDisabled {
        width: 66%;
        height: 58%;
        position: absolute;
        background-color: gray;
        z-index: 10;
        opacity: 0.5;
    }

    .paywall {
        background-color: white;
        margin: 5ex 4em;
        padding: 1em;
        position: absolute;
        width: 56%;
        height: 48%;
        z-index: 11;
        font-size: 12px!important;

        .link-style-1 {
            border-radius: 2em !important;
            padding: 0.6em 3em;
            box-shadow: none;
            background-color: #fce18a !important;
            min-width: 12em;
            margin: 0;
            color: #000 !important;
            text-decoration: none;
        }
    }
}

.author {
    p.author_small_bold {
        font-size: 13px;
        font-weight: bolder;
        margin-bottom: auto;
    }

    .header {
        background-color: @color_primary_800;
        color: @white-text-color;
        border-top-left-radius: 1.2em;
        border-bottom-left-radius: 1.2em;
        border-top-right-radius: 1em;
        border-bottom-right-radius: 1em;
        min-height: 200px;
    }

    .image {
        img {
            margin: 0;
            border-radius: 0;
            border-top-left-radius: 1em;
            border-bottom-left-radius: 1em;
        }
    }

    .name {
        margin-left: 20px;
    }

    .social {
        i {
            font-size: 22px;
        }
    }

    .affiliation, .activities, .curriculum, .contact, .social {
        margin-bottom: 15px;
    }

    .content{
        h3{
            text-decoration: none;
            font-size: x-large;
        }

        h1, h2, h3, h4, h5, h6 {
            color: @heading_dark;
        }

        .author_small_bold {
            font-family: 'Proxima Nova Alt Bold';
            font-weight: 600;
            font-size: 12px;
        }
    }
}
.Job-Frame{
    border-style: solid;
	border-width: thin;
	border-color: black;
}

.configurable-widget {
    margin-bottom: 25px;
    &.superbanner{
        margin-top: 20px;
    }

    .color-primary {
        background-color: @color_primary_800;

        .btn {
            &.newsletter-button{
                color: @color_yellow!important;
                background-color: @color_primary_800;
            }
        }
    }

    .color-secondary {
        background-color: @color_primary_700;

        .btn {
            &.newsletter-button{
                color: @white-text-color!important;
                background-color: @color_primary_700;
            }
        }
    }

    .clear {
        background-color: @white-text-color;

        .btn {
            &.newsletter-button{
                color: @color_primary_800!important;
                background-color: @white-text-color;
            }
        }
    }

    .color-primary, .color-secondary {
        border-radius: 10px;
        color: @white-text-color;

        .fachthemen {
            font-size: 0.7rem;
            margin: 15px 12px 10px;

            span {
                background-color: @color_primary_900;
                padding: 5px;
                border-radius: 10px;
            }
        }

        .content {
            margin: 0px 12px;

            .title {
                padding: 0;
                font-weight: bold;
                font-size: 1.2rem!important;
                color: @white-text-color!important;
            }

            .teaserText {
                font-size: 0.9rem;
            }

            .email {
                border-radius: 7px!important;
                margin: 10px 0 15px;
                font-size: 0.9rem;
                opacity: 80%;
            }

            .btn {
                &.newsletter-button{
                    margin: 0;
                    border-radius: 0!important;
                    box-shadow: none;
                    padding: 0;
                }
            }

            .privacy-wrapper {
                margin-top: 10px;
                margin-bottom: 10px;

                input[id="accept"] + label:before,
                input[id="privacy"] + label:before {
                    border-color: @white-text-color;
                }

                input[id="accept"]:checked + label:before,
                input[id="privacy"]:checked + label:before {
                    border-right: 2px solid @white-text-color;
                    border-bottom: 2px solid @white-text-color;
                    border-top: 2px solid transparent;
                    border-left: 2px solid transparent;
                }

                .privacyLabel {
                    font-size: 0.7rem;
                    line-height: initial;
                }
            }
        }

        .linkButton {
            button {
                margin: 10px 0px;
                width: 100%;
                font-size: 0.9rem;
            }
        }
    }

    .clear{
        border-radius: 10px;
        border: 2px solid @color_primary_900;

        .fachthemen {
            font-size: 0.7rem;
            margin: 10px 12px;

            span {
                background-color: @background_350;
                padding: 5px;
                border-radius: 10px;
            }
        }

        .content {
            margin: 0px 12px;

            .title {
                padding: 0;
                font-weight: bold;
                font-size: 1.2rem!important;
                color: @heading_dark;
            }

            .teaserText {
                font-size: 0.9rem;
                color: @body_1;
            }

            .email {
                border-radius: 7px!important;
                margin: 10px 0 15px;
                font-size: 0.9rem;
                opacity: 80%;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
            }

            .btn {
                &.newsletter-button{
                    margin: 0;
                    border-radius: 0!important;
                    box-shadow: none;
                    padding: 0;
                }
            }

            .privacy-wrapper {
                margin-top: 10px;

                .privacyLabel {
                    font-size: 0.7rem;
                }
            }
        }

        .linkButton {
            button {
                margin: 10px 0px;
                width: 100%;
                font-size: 0.9rem;
            }
        }
    }

    .simple_widget {
        margin-bottom: 20px;



        .title {

            margin-top: 10px;
            margin-bottom: 10px;

        }

        .teaserText {

            

            a {
                text-decoration: underline;
            }
        }
    }
}

.back-button-widget {
    margin-bottom: 20px;
}

.footer-navigation {
    .normal-footer, .main-footer {
        margin-top: 3ex;
    }

    .footer-info  {
        .logo-futuro {
            max-width: 6em;
            margin-left: 20px;
            image-rendering: -webkit-optimize-contrast;
        }
    }

    .normal-footer {
        li {
            list-style: none;

            h4 {
                font-size: 12px!important;
            }

            .sub-nav {
                li {
                    a {
                        color: inherit;
                        font-size: 14px;
                    }
                }
            }
        }
        ul.social {
            margin-top: -25px;

            li.social {
                float: left;
                margin-right: 5px;

                img.social {
                    width: 30px;
                }
            }
        }

    }
}

.newsletter-footer-content-wrapper {
    .checkboxes{
        input[id="newsletter-privacy"]:checked + label:before,  input[id="newsletter-accept"]:checked + label:before{
            border-right: 2px solid @white-text-color;
            border-bottom: 2px solid @white-text-color;
            border-top: 2px solid transparent!important;
            border-left: 2px solid transparent!important;
        }
    }
}

.share-mail-article {
    margin-top: 3ex!important;

    .share-mail-wrapper{
        background-color: @color_primary;
        color: @white-text-color!important;
        border-color: @color_primary!important;
    }
}

.date-article {
    .pubdate {
        margin-top: 0!important;

        .title {
            font-size: 16px!important;
            color: @body_2;
        }
    }
}

.reading-time-article {
    margin-top: 0!important;

    .time-wrapper {
        color: @body_2!important;
        border: none!important;
        padding: 0!important;
    }
}

.authors-article {
    font-size: 16px!important;
    padding: 0 10px!important;

    .author {
        color: @black-text-color;
    }

    .link {
        color: @color_primary_700;
        text-decoration: underline;
    }
}

.article-100 {
    max-width: 100%!important;
}

.teaser-chips-wrapper-list {
    bottom: 15px!important;
}

.sidebar {
    .main_banner {
        max-width: none;
        margin-bottom: 25px;

        .row {
            margin: 0;

            .article-list {
                padding-right: 0;

                .img-fluid {
                    margin-bottom: 0;
                }
            }
        }
    }
}