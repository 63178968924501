#imgZoom {

    overflow: hidden;

    .modal-dialog {
        max-width: 65%;
        height   : 85%;
        margin   : 30px auto;

        // max-height  : 65%;
        // height      : 75%;
        //min-height   : 85%;
        // margin      : auto;
        // max-width   : 85%;
        // height      : 100%;

        .modal-content {

            //height     : 100%;
            //max-height : 100%;
            //overflow   : auto;
            width        : 100%;
            height       : 100%;
            border       : 0.5em white solid;
            border-radius: 0.5em;


            .modal-body {

                //max-height: 100%;
                //width     : 100%;
                //height    : 100%;

                width     : 100%;
                height    : 100%;
                max-height: none;
                display   : flex;

                img {
                    /*height      : auto;
                    max-width     : 100%;
                    max-height    : 100%;
                    margin-left   : auto;
                    margin-right  : auto;
                    width         : auto;
                    display       : block;*/

                    width     : auto;
                    height    : auto;
                    max-width : 100%;
                    max-height: 100%;
                    margin    : auto;
                    display   : block;
                }
            }
        }




    }
}

.html-string {
    img {
        border: 0.5em white solid;
        cursor: pointer;
        vertical-align: top;
        margin-top: 2.5ex;
    }
}